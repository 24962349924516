import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { useNavigate , useLocation  } from "react-router-dom";
import "toastr/build/toastr.min.css";

import
{
    fetchData,
} from "../../helpers/databaseHelpers";

let SearchResult = () =>
{
    let baseUrl = process.env.REACT_APP_BACKEND_URL;

    let navigate = useNavigate();
    const token = localStorage.getItem( "authToken" );

    if ( !token )
    {
        console.log( "No token found. Redirecting to login." );
        navigate( "/" );
    }


     // Get Prams 

     const location = useLocation();

     const params = new URLSearchParams(location.search);
     const data = params.get("data");
    

    // User 
    let [ userDeatils, setUserDetails ] = useState( [] );

    let [ age, setAge ] = useState( 'unset' );
    let [ spiritualPaths, setSpiritualPaths ] = useState( 'unset' );
    let [ income, setIncome ] = useState( 'unset' );
    let [ within, setWithin ] = useState( 'unset' );
    let [ newMatches, setNewMatches ] = useState( [] );

    
    // Get Connect  
    let [ connectList, setConnectList ] = useState( [] );

   

    useEffect( () =>
    {
        const loadDetails = async () =>
        {
            try
            {
                const whereObj = { token: token };
                const userData = await fetchData( "*", "users", whereObj );
                setUserDetails( userData[ 0 ] );
            } catch ( error )
            {
                console.error( "Error loading user details:", error );
            }
        };
        loadDetails();
    }, [ token ] );


    useEffect( () =>
    {
        let fetchNewMatches = async () =>
        {
            await fetch( `${ baseUrl }/searchResult`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify( { user: userDeatils.uniquecode , data  } ),
            } ).then( response =>
            {
                if ( !response.ok )
                {
                    throw new Error( "Network response was not ok" );
                }
                return response.json();
            } )
                .then( data =>
                {
                    setNewMatches( data.data );
                } )
                .catch( error =>
                {
                    console.error( "There was a problem with the fetch operation:", error );
                } );
        }

        fetchNewMatches();
    }, [  data , userDeatils  ] )





   // Add Fav/Rem 

   let favRem = async ( vid, type, el ) =>
    {
        {
            await fetch( `${ baseUrl }/addFavRem`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify( { vid, 'uid': userDeatils.uniquecode, type } ),
            } ).then( response =>
            {
                if ( !response.ok )
                {
                    throw new Error( "Network response was not ok" );
                }
                return response.json();
            } )
                .then( data =>
                {
                    if ( data.data == 'add' )
                    {
                        el.setAttribute( 'value', 'yes' );
                        el.style.color = 'red';
                        el.style.textShadow = '0px 5px 10px rgb(251 0 10)';
                    } else
                    {
                        el.setAttribute( 'value', 'no' );
                        el.style.color = '#f6aab2';
                        el.style.textShadow = '0px 5px 10px rgb(209, 134, 137)';
                    }
                } )
                .catch( error =>
                {
                    console.error( "There was a problem with the fetch operation:", error );
                } );
        }
    }


    // Connect Now
    let connectUser = async ( vid, type, up , cu ) =>
    {
        {
            await fetch( `${ baseUrl }/connectUser`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify( { vid, 'uid': userDeatils.uniquecode, type } ),
            } ).then( response =>
            {
                if ( !response.ok )
                {
                    throw new Error( "Network response was not ok" );
                }
                return response.json();
            } )
                .then( data =>
                {
                    if (data.data == 'add') {
                        document.getElementById(cu).style.display = 'none'
                        document.getElementById(up).style.display = 'flex'
                    } 
                } )
                .catch( error =>
                {
                    console.error( "There was a problem with the fetch operation:", error );
                } );
        }
    }


    // Get Connect Data

    useEffect(() => {
        let load = async () => {
            await fetch( `${ baseUrl }/getConnectUser`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify( { 'uid': userDeatils.uniquecode } ),
            } ).then( response =>
            {
                if ( !response.ok )
                {
                    throw new Error( "Network response was not ok" );
                }
                return response.json();
            } )
                .then( data =>
                {
                    setConnectList(data.data);
                })
                .catch( error =>
                {
                    console.error( "There was a problem with the fetch operation:", error );
                } );
        }

        load();
    }, [userDeatils])



    let checkConnectUser = (value) => {
        let newData = connectList.filter((el , i) => {return el.visitor_id == value});
        return newData.length;
    }


    

   
    return (
        <>
            <section className="w70 new_matches fcs-display m_auto">
                <div className="match_right_bx" style={ { width : '100%'} }>
                    {
                        Array.isArray( newMatches ) && newMatches.length > 0 ? (
                            newMatches.map( ( el, i ) => (
                                <div className="card_matches w-100 fcs-display" key={ i } id={`card${i}`}>
                                    <div className="img_bx">
                                        <img src={ el.profile_pic } onError={(e) => { e.target.onerror = null;  e.target.src = 'https://svgsilh.com/png-512/659651.png';}} alt="profile pic" />
                                    </div>
                                    <div className="content_bx">
                                        <h4>{ el.first_name } { el.last_name }  <i className="bi bi-shield-check"></i></h4>
                                        <div className="cont_head w-100 fcs-display">
                                            <h6><i className="bi bi-chat-right-dots-fill"></i> Online 3h ago</h6>
                                            <div className="r_fav">
                                                <span></span>
                                                <i className="bi bi-heart-fill" value={ el.is_match == 'yes' ? 'yes' : 'no' } style={ el.is_match == 'yes' ? { color: 'red', textShadow: '0px 5px 10px rgb(251 0 10)' } : { color: '#f6aab2', textShadow: ' 0px 5px 10px rgb(209, 134, 137)' } } onClick={ ( e ) => { e.target.getAttribute( 'value' ) == 'no' ? favRem( el.uniquecode, 'add', e.target ) : favRem( el.uniquecode, 'rem', e.target ) } } ></i>
                                            </div>
                                        </div>
                                        <ul className="fcs-display w-100">
                                            <li>{ el.age } yrs, { el.height }"</li>
                                            <li>{ el.community }</li>
                                            <li>{ el.country }, { el.state }</li>
                                            <li>{ el.marital_status }</li>
                                            <li>{ el.mother_tongue }</li>
                                            <li>{ el.religion }</li>
                                        </ul>
                                        <p>{ el.about } <Link to={ `/user/${ el.uniquecode }` }>Show more.</Link></p>
                                    </div>
                                    <div className="connect_bx">

                                        {

                                        checkConnectUser(el.uniquecode) == 0 ?
                                   <>
                                        <div className="connect_profile w-100 fcc-display connectNow" id={`current${i}`} >
                                            <h6>Like this profile?</h6>
                                            <i className="bi bi-check-circle-fill" onClick={(e) => {connectUser( el.uniquecode, 'add', `upgrade${i}` , `current${i}` )}}></i>
                                            <p>Connect Now</p>
                                        </div>

                                        <div className="connected_profile w-100 fcc-display" id={`upgrade${i}`} style={{display: 'none'}}>
                                            <p>
                                                <a href="/mymatches">Upgrade</a>
                                                to Contact her directly
                                            </p>
                                            <button>
                                                <i className="bi bi-telephone-fill"></i>
                                                Call
                                            </button>
                                            <button>
                                                <i className="bi bi-whatsapp"></i>
                                                Whatsaap
                                            </button>
                                            <button>
                                                <i className="bi bi-chat-right-dots-fill"></i>
                                                Chat
                                            </button>
                                        </div>
                                   </>
                                        
                                        :
                                        <div className="connected_profile w-100 fcc-display upgrabeUser">
                                            <p>
                                                <a href="/mymatches">Upgrade</a>
                                                to Contact her directly
                                            </p>
                                            <button>
                                                <i className="bi bi-telephone-fill"></i>
                                                Call
                                            </button>
                                            <button>
                                                <i className="bi bi-whatsapp"></i>
                                                Whatsaap
                                            </button>
                                            <button>
                                                <i className="bi bi-chat-right-dots-fill"></i>
                                                Chat
                                            </button>
                                        </div>

                                  }
                                    </div>
                                   
                                </div>
                            ) )

                        ) : (
                            <p style={ { textAlign: "center", marginTop: '20px' } }>No matches found.</p>
                        )
                    }
                </div>
            </section>
        </>
    )
}


export default SearchResult;