import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto', paddingTop: '105px' }}>
      <h1>Privacy Policy</h1>
      <p>
        At <strong>satsangirishtey.com</strong>, we are committed to protecting your privacy. This Privacy Policy outlines the types of personal information we collect, how we use and protect that information, and your rights concerning your personal data.
      </p>

      <h2>1. Information We Collect</h2>
      <p>We collect the following types of information when you use our website:</p>
      <ul>
        <li><strong>Personal Information:</strong> This includes your name, email address, phone number, and other details you may provide when subscribing or registering on our site.</li>
        <li><strong>Non-Personal Information:</strong> We may collect non-identifying information, such as browser type, pages visited, and time spent on the site.</li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>We may use the information we collect for the following purposes:</p>
      <ul>
        <li>To personalize your experience on the website.</li>
        <li>To improve our website based on user feedback and usage data.</li>
        <li>To process transactions and provide the services you’ve requested.</li>
        <li>To send periodic emails related to your account, services, or promotions.</li>
      </ul>

      <h2>3. How We Protect Your Information</h2>
      <p>
        We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet is completely secure, and we cannot guarantee absolute security.
      </p>

      <h2>4. Sharing Your Information</h2>
      <p>We do not sell, trade, or rent your personal information to third parties. We may share your information with trusted third parties who assist us in operating our website, conducting our business, or providing services, as long as these parties agree to keep your information confidential.</p>

      <h2>5. Cookies</h2>
      <p>
        Our website may use "cookies" to enhance the user experience. Cookies are small files stored on your device by your browser. You may choose to set your browser to refuse cookies, but this may limit your ability to use certain features of the site.
      </p>

      <h2>6. Third-Party Links</h2>
      <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices of those websites. We encourage you to review their privacy policies before providing any personal information.</p>

      <h2>7. Your Rights</h2>
      <p>
        You have the right to access, correct, or delete your personal information at any time. If you wish to do so, please contact us at info@satsangirishtey.com.
      </p>

      <h2>8. Changes to This Policy</h2>
      <p>
        We reserve the right to update this Privacy Policy at any time. Any changes will be posted on this page, and the effective date will be updated at the top of the policy.
      </p>

      <h2>9. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy, please contact us at info@satsangirishtey.com.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
