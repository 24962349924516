const TermsOfUse = () => {
    return (
      <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' , paddingTop: '105px' }}>
        <h1>Terms of Use</h1>
        <p>Welcome to <strong>satsangirishtey.com</strong>. By accessing or using our website, you agree to comply with and be bound by the following terms and conditions:</p>
  
        <h2>1. Acceptance of Terms</h2>
        <p>By accessing, browsing, or using this website, you acknowledge that you have read, understood, and agree to be bound by these terms. If you do not agree to these terms, you must not use this website.</p>
  
        <h2>2. Subscription</h2>
        <p>Users may subscribe to various services on this website. Subscriptions are governed by the terms and conditions outlined in the individual service agreements. All purchases are final.</p>
  
        <h2>3. Refund Policy</h2>
        <p>We do not provide any kind of refund for the subscription purchased via our website regardless of the reason. Once a subscription is purchased, it cannot be refunded or canceled.</p>
  
        <h2>4. Intellectual Property</h2>
        <p>All content on this website, including text, graphics, logos, and images, is the property of satsangirishtey.com or its content suppliers and is protected by applicable copyright laws.</p>
  
        <h2>5. Limitation of Liability</h2>
        <p>Under no circumstances shall satsangirishtey.com be liable for any damages that result from the use or inability to use this website or any of its services.</p>
  
        <h2>6. Governing Law</h2>
        <p>These terms shall be governed by and construed in accordance with the laws of the country in which the website operates.</p>
  
        <h2>7. Changes to Terms</h2>
        <p>We reserve the right to update or modify these terms at any time without prior notice. Your continued use of the website following the posting of changes constitutes your acceptance of such changes.</p>
  
        <p>If you have any questions about these Terms of Use, please contact us at info@satsangirishtey.com.</p>
      </div>
    );
  };
  
  export default TermsOfUse;
  