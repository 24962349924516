import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import "toastr/build/toastr.min.css";
import toastr from "toastr";

import {
  fetchProfiles,
  fetchCommunity,
  fetchReligion,
  fetchIncomeRange,
  fetchQualification,
  fetchCountry,
  fetchState,
  fetchCity,
  fetchMaritalStatus,
  fetchMotherTongue,
  fetchHeight,
  fetchStateMultiple,
  fetchData,
} from "../../helpers/databaseHelpers";

let BasicSearch = () => {
  let baseUrl = process.env.REACT_APP_BACKEND_URL;
  const [userDetails, setUserDetails] = useState([]);
  const [search, setSearch] = useState("");
  const [communityes, setCommunityes] = useState([]);
  const [MaritalStatus, setMaritalStatus] = useState([]);
  const [ReligonStatus, setReligonStatus] = useState([]);
  const [ToungeStatus, setTounge] = useState([]);
  const [CountryStatus, setCountryStatus] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [eductionList, setEductionList] = useState([]);
  const [incomeList, setIncomeList] = useState([]);
  const [dietList, setDietList] = useState([{ label: 'Vegetarian', value: 'Vegetarian' } , { label: 'Non-vegetarian', value: 'Non-vegetarian' } , { label: 'Eggetarian', value: 'Eggetarian' } , { label: 'Occasionally Non-vegetarian', value: 'Occasionally Non-vegetarian' }]);
  const [height, setHeight] = useState([]);
  const [startHeight, setStartHeight] = useState();
  const [endHeight, setEndHeight] = useState();
  const [Reset,setReset] = useState(false);
  let navigate = useNavigate();
  const token = localStorage.getItem("authToken");
  
  if (!token) {
    console.log("No token found. Redirecting to login.");
    navigate("/");
  }

  
  useEffect(() => {
    
    const loadDetails = async () => {
      try {
        const whereObj = { token: token };
        const userData = await fetchData("*", "users", whereObj);
        const user = userData[0];
        setUserDetails(user);
        const whereObj1 = { uniquecode: user.uniquecode };
        const searchData = await fetchData("*", "user_search", whereObj1);
        const searchRow = searchData[0];
        if (searchRow.community) {
          const communitySet = searchRow.community
            .split(",")
            .map((com) => ({ label: com, value: com }));
          setSpritiualPath(communitySet);
        }

        SetStart_age(searchRow.start_age);
        SetEnd_age(searchRow.end_age);
        setStartHeight(searchRow.start_height);
        setEndHeight(searchRow.end_height);

        if (searchRow.marital_status) {
          const maritalSet = searchRow.marital_status
            .split(",")
            .map((m1) => ({ label: m1, value: m1 }));
          setMarital(maritalSet);
        }

        setVisibility(searchRow.kids);

        if (searchRow.religion) {
          const religionSet = searchRow.religion
            .split(",")
            .map((m2) => ({ label: m2, value: m2 }));
          setReligion(religionSet);
        }

        if (searchRow.mother_tongue) {
          const TongueSet = searchRow.mother_tongue
            .split(",")
            .map((m3) => ({ label: m3, value: m3 }));
          setToungess(TongueSet);
        }

        if (searchRow.country) {
          const CountrySet = searchRow.country
            .split(",")
            .map((m4) => ({ label: m4, value: m4 }));
          setCountry(CountrySet);
        }

        if (searchRow.state) {
          const StateSet = searchRow.state
            .split(",")
            .map((m5) => ({ label: m5, value: m5 }));
          setState(StateSet);
        }

        setSearch(searchRow);
      } catch (error) {
        console.error("Error loading user details:", error);
      }
    };
    loadDetails();
  }, [token,Reset]);

  const handleReset = async (event)=>{
    event.preventDefault();
    const formData = new FormData();
    formData.append("uniquecode", userDetails.uniquecode);

    try {
      const response = await fetch(`${baseUrl}/reset`, {
        method: "POST",
        body: formData
      });
      const data = await response.json();
      if (data.status) {
        window.location.reload();
      } else {
        toastr.error("Failed to reset. Please try again.");
      }
    } catch (error) {
      toastr.error("An error occurred. Please try again later.");
    }
  }

  useEffect(() => {
    const loadCommunity = async () => {
      try {
        const communityData = await fetchCommunity();
        const communityess = [];
        communityData.map((comu) => {
          communityess.push({ label: comu.name, value: comu.name });
        });
        setCommunityes(communityess);
      } catch (error) {
        console.error("Error loading community:", error);
      }
    };

    loadCommunity();

    const loadHeight = async () => {
      try {
        const heightData = await fetchHeight();
        setHeight(heightData);
        setStartHeight(heightData[0].lable)
        setEndHeight(heightData.reverse()[0].lable)
      } catch (error) {
        console.error("Error loading community:", error);
      }
    };

    loadHeight();

    const loadMarital = async () => {
      try {
        const maritalData = await fetchMaritalStatus();
        const maritalll = [];
        maritalData.map((comu) => {
          maritalll.push({ label: comu.name, value: comu.name });
        });
        setMaritalStatus(maritalll);
      } catch (error) {
        console.error("Error loading community:", error);
      }
    };

    loadMarital();

    const loadReligion = async () => {
      try {
        const religionData = await fetchReligion();
        const religionn = [];
        religionData.map((comu) => {
          religionn.push({ label: comu.name, value: comu.name });
        });
        setReligonStatus(religionn);
      } catch (error) {
        console.error("Error loading community:", error);
      }
    };

    loadReligion();


    const loadEduction = async () => {
      try {
        const eductionData = await fetchQualification();
        const eductionn = [];
        eductionData.map((comu) => {
           eductionn.push({ label: comu.name, value: comu.name });
        });
        setEductionList(eductionn);
      } catch (error) {
        console.error("Error loading community:", error);
      }
    };

    loadEduction();


    const loadIncome = async () => {
      try {
        const incomeData = await fetchIncomeRange();
        const incomemm = [];
        incomeData.map((comu) => {
           incomemm.push({ label: comu.lable, value: comu.lable });
        });
        setIncomeList(incomemm);
      } catch (error) {
        console.error("Error loading community:", error);
      }
    };

    loadIncome();


    const loadTounge = async () => {
      try {
        const toungeData = await fetchMotherTongue();
        const toungee = [];
        toungeData.map((comu) => {
          toungee.push({ label: comu.name, value: comu.name });
        });
        setTounge(toungee);
      } catch (error) {
        console.error("Error loading community:", error);
      }
    };

    loadTounge();

    const loadCountry = async () => {
      try {
        const countryData = await fetchCountry();
        const countryy = [];
        countryData.map((comu) => {
          countryy.push({ label: comu.country, value: comu.country });
        });
        setCountryStatus(countryy);
      } catch (error) {
        console.error("Error loading community:", error);
      }
    };

    loadCountry();
  }, []);

  const loadState = async (country) => {
    try {
      const stateData = await fetchStateMultiple(country);
      const _stateNames = [];
      stateData.map((st) => {
        _stateNames.push({ label: st.state, value: st.state });
      });
      setStateList(_stateNames);
    } catch (err) {
      console.error("Error loading state:", err);
    }
  };

  const [SpritiualPath, setSpritiualPath] = useState([]);
  const [Marital, setMarital] = useState([]);
  const [Religion, setReligion] = useState([]);
  const [Toungess, setToungess] = useState([]);
  const [Country, setCountry] = useState([]);
  const [State, setState] = useState([]);
  const [eduction, setEduction] = useState([]);
  const [income, setIncome] = useState([]);
  const [diet, setDiet] = useState([]);

  const handleChange = (SpritiualPath) => {
    setSpritiualPath(SpritiualPath);
  };

  const handleChangeMarital = (Marital) => {
    setMarital(Marital);
  };

  const handleChangeReligion = (Religion) => {
    setReligion(Religion);
  };

  const handleChangeTounge = (Tounge) => {
    setToungess(Tounge);
  };

  const handleChangeEduction = (edu) => {
    setEduction(edu);
  };

  const handleChangeIncome = (inc) => {
    setIncome(inc);
  };

  const handleChangeDiet = (di) => {
    setDiet(di);
  };

  const handleChangeCountry = (Country) => {
    setCountry(Country);
    setState([]);
    loadState(Country);
  };

  const handleChangeState = (State) => {
    setState(State);
  };

  let [Start_age, SetStart_age] = useState(18);
  let [End_age, SetEnd_age] = useState(60);

  const handleChangeAge = (e, input) => {
    const value = e.target.value;

    if (/^\d*$/.test(value)) {
      const age = parseInt(value, 10);

      if (input == "start") {
        SetStart_age(value);
      } else {
        SetEnd_age(value);
      }
    }
  };

  function handelFoucs(id) {
    document.getElementById(id).classList.add("drop_bx_active");
  }

  function handelFoucsout(id) {
    let el = document.getElementById(id);
    if (el) {
      setTimeout(() => {
        document.getElementById(id).classList.remove("drop_bx_active");
      }, 300);
    }
  }

  const [visibility, setVisibility] = useState("dosnt_matter");

  const handleChangeChildern = (event) => {
    setVisibility(event.target.value);
  };

  const submitBasicSearchForm = async (event) => {
    event.preventDefault();
    let queryData = [SpritiualPath.map((el) => el.label) , [Start_age , End_age ]  , [ height.filter((el) => el.lable == startHeight)[0].inch , height.filter((el) => el.lable == endHeight)[0].inch], [visibility] ,  Marital.map((el) => el.label)  , Religion.map((el) => el.label)  , Country.map((el) => el.label)  , State.map((el) => el.label) , eduction.map((el) => el.label)  ,income.map((el) => el.label)  ,Toungess.map((el) => el.label) , diet.map((el) => el.label)];
    navigate(`/result?data=${encodeURIComponent(JSON.stringify(queryData))}`);
  };

  const [profileId,setProfileId] = useState("");
  const handleChangeProfileId = async (event)=>{
    const profileId = event.target.value;
    setProfileId(profileId);
   
  }

  const searchProfileById = async (event) =>{
    event.preventDefault();
    if(profileId!='' && profileId!=userDetails.uniquecode){
      const whereObj = { uniquecode: profileId };
      const userData = await fetchData("*", "users", whereObj);
      if(userData.length>0){
        navigate('/user/'+ profileId);
      }else{
        toastr.error("Profile not found");
      }
     
    }else{
      toastr.error("Please enter profile id");
    }
  }

 

  return (
    <>
      <section className="w70 basic_search fcs-display m_auto">
        <form
          action="javascript:void(0)"
          method="post"
          id="basicSearchForm"
          style={{ width: "100%" }}
          onSubmit={submitBasicSearchForm}
        >
          <div className="basic_search_left_bx">
            <div className="basic_search_card">
              <label>Spiritual Path</label>
              <Select
                className="w70"
                style={{ zIndex: 9999, position: "relative" }}
                isMulti
                value={SpritiualPath}
                onChange={handleChange}
                options={communityes}
              />
            </div>
            <div className="basic_search_card">
              <label>Age</label>
              <div className="inputs fcs-display">
                <input
                  type="number"
                  placeholder="Start Age"
                  style={{ marginRight: "10px" }}
                  min={ 18 } max={ 61 } 
                  onChange={ ( e ) => { End_age > e.target.value && 18 <= e.target.value && 61 >= e.target.value ? SetStart_age( e.target.value ) : SetStart_age( Start_age ) } }
                  value={Start_age}
                />{" "}
                <span
                  style={{
                    margin: "0 5px",
                    position: "relative",
                    left: "-5px",
                    top: "5px",
                    color: "#fff",
                  }}
                >
                  to
                </span>
                <input
                  type="text"
                  placeholder="End Age"
                  onChange={ ( e ) => { Start_age < e.target.value && 18 <= e.target.value ? SetEnd_age( e.target.value ) : SetEnd_age( End_age ) } }
                  value={End_age}
                  max={50}
                  min={18}
                />
              </div>
            </div>
            <div className="basic_search_card">
              <label>Height</label>
              <div className="basic_section fcs-display">
                <div className="filter_card">
                  <input
                    type="text"
                    placeholder="Start Height"
                    value={startHeight}
                    onFocus={() => handelFoucs("sheight_for_drop_bx")}
                    onBlur={() => handelFoucsout("sheight_for_drop_bx")}
                  />
                  <i className="bi bi-caret-down-fill"></i>
                  <div className="drop_bx" id="sheight_for_drop_bx">
                    {height.map((el, i) => (
                      <li key={i} onClick={() => setStartHeight(el.lable)}>
                        {el.lable}
                      </li>
                    ))}
                  </div>
                </div>
                <span>To</span>
                <div className="filter_card">
                  <input
                    type="text"
                    placeholder="End Height"
                    value={endHeight}
                    onFocus={() => handelFoucs("eheight_drop_bx")}
                    onBlur={() => handelFoucsout("eheight_drop_bx")}
                  />
                  <i className="bi bi-caret-down-fill"></i>
                  <div className="drop_bx" id="eheight_drop_bx">
                    {height.map((el, i) => (
                      <li key={i} onClick={() => setEndHeight(el.lable)}>
                        {el.lable}
                      </li>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="basic_search_card">
              <label>Marital Status</label>
              <Select
                className="w70"
                style={{ zIndex: 9999, position: "relative" }}
                isMulti
                value={Marital}
                onChange={handleChangeMarital}
                options={MaritalStatus}
              />
            </div>

            <div className="basic_search_card">
              <label>Kids</label>
              <div className="radio_btns fc-display">
                <div className="card">
                  <input
                    type="radio"
                    id="all1"
                    name="kids"
                    value="dosnt_matter"
                    checked={visibility === "dosnt_matter"}
                    onChange={handleChangeChildern}
                  />
                  <label htmlFor="all1">Doesn't Matter</label>
                </div>

                <div className="card">
                  <input
                    type="radio"
                    id="connections1"
                    name="kids"
                    value="No"
                    checked={visibility === "No"}
                    onChange={handleChangeChildern}
                  />
                  <label htmlFor="connections1">No</label>
                </div>

                
              </div>
            </div>

            <div className="basic_search_card">
              <label>Religion</label>
              <Select
                className="w70"
                style={{ zIndex: 9999, position: "relative" }}
                isMulti
                value={Religion}
                onChange={handleChangeReligion}
                options={ReligonStatus}
              />
            </div>
            <div className="basic_search_card">
              <label>Country Living in</label>
              <Select
                className="w70"
                style={{ zIndex: 9999, position: "relative" }}
                isMulti
                value={Country}
                onChange={handleChangeCountry}
                options={CountryStatus}
              />
            </div>

            <div className="basic_search_card">
              <label>State Living in</label>
              <Select
                className="w70"
                style={{ zIndex: 9999, position: "relative" }}
                isMulti
                value={State}
                onChange={handleChangeState}
                options={StateList}
              />
            </div>

            {/* Advanced Search  */}

           <h4 className="color_text fcs-display border_text" style={{margin: '30px 0'}}>Advanced Search</h4>
            
            <div className="basic_search_card">
              <label>Education</label>
              <Select
                className="w70"
                style={{ zIndex: 9999, position: "relative" }}
                isMulti
                value={eduction}
                onChange={handleChangeEduction}
                options={eductionList}
              />
            </div>
            <div className="basic_search_card">
              <label>Income</label>
              <Select
                className="w70"
                style={{ zIndex: 9999, position: "relative" }}
                isMulti
                value={income}
                onChange={handleChangeIncome}
                options={incomeList}
              />
            </div>
            <div className="basic_search_card">
              <label>Mother Tongue</label>
              <Select
                className="w70"
                style={{ zIndex: 9999, position: "relative" }}
                isMulti
                value={Toungess}
                onChange={handleChangeTounge}
                options={ToungeStatus}
              />
            </div>
            <div className="basic_search_card">
              <label>Diet</label>
              <Select
                className="w70"
                style={{ zIndex: 9999, position: "relative" }}
                isMulti
                value={diet}
                onChange={handleChangeDiet}
                options={dietList}
              />
            </div>


            <div className="basic_search_card fcc-display w100">
              <button className="btn_reset n_padding " type="submit">
                Search
              </button>
              <button className="btn_reset reset" type="button" onClick={handleReset}>
                Reset
              </button>
            </div>
          </div>
        </form>
        <div className="basic_search_right_bx">
          <div className="filter">
            <h3>
              <i className="bi bi-search"></i> Profile ID Search
            </h3>
            <div className="filter_card">
              <div className="filter_head fcs-display w100">
                <input
                  type="search"
                  name=""
                  id=""
                  value={profileId}
                  onChange={handleChangeProfileId}
                  placeholder="Enter Profile ID"
                  className="w80"
                />
                <button className="reset_btn" onClick={searchProfileById}>Go</button>
              </div>
            </div>
          </div>
          <div className="profile ad_p fcc-display w100">
           <Link to='/plan'> <img src="img/contact_call.jpg" alt=""  /></Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default BasicSearch;
