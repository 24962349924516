import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import {
    fetchCommunity,
} from "../../helpers/databaseHelpers";

let Contactus = () => {
    let baseUrl = process.env.REACT_APP_BACKEND_URL;
    const [error, setError] = useState("");
    const [communityes, setCommunityes] = useState([]);
    useEffect(() => {
        const loadCommunity = async () => {
            try {
                const communityData = await fetchCommunity();
                const communityess = [];
                communityData.map((comu) => {
                    communityess.push(comu.name);
                });
                setCommunityes(communityess);
            } catch (error) {
                console.error("Error loading community:", error);
                setError("Failed to load community");
            }
        };

        loadCommunity();
    }, []);

    let [community, SetCommunity] = useState("");
    let [community1, SetCommunity1] = useState("");

    function handelFoucs(id) {
        document.getElementById(id).classList.add("drop_bx_active");
    }

    function handelFoucsout(id) {
        let el = document.getElementById(id);
        if (el) {
            setTimeout(() => {
                document.getElementById(id).classList.remove("drop_bx_active");
            }, 300);
        }
    }

    const [job_name,setJobName] = useState("");
    const [job_phone,setJobPhone] = useState("");

    const [contact_name,setContactName] = useState("");
    const [contact_phone,setContactPhone] = useState("");
    const [message,setMessage] = useState("");

    const [job_name_err,setJobNameErr] = useState("");
    const [job_phone_err,setJobPhoneErr] = useState("");
    const [community_err,setCommunityErr] = useState("");
    const [messageErr,setMessageErr] = useState("");

    const [contact_name_err,setContactNameErr] = useState("");
    const [contact_phone_err,setContactPhoneErr] = useState("");
    const [contact_community_err,setContactCommunityErr] = useState("");

    function jobValid(){
        let valid = true;
        if(job_name==''){
            setJobNameErr('Name is Required!');
            valid = false;
        }

        if(job_phone==''){
            setJobPhoneErr("Phone is Required!");
            valid = false;
        }

        if(community==''){
            setCommunityErr("Spritual Path is Required!");
            valid = false;
        }

        return valid;
    }

    function ContactValid(){
        let valid1 = true;
        if(contact_name==''){
            setContactNameErr('Name is Required!');
            valid1 = false;
        }

        if(contact_phone==''){
            setContactPhoneErr("Phone is Required!");
            valid1 = false;
        }

        if(message==""){
            setMessageErr("Message is Required!");
            valid1 = false;
        }
        if(community1==''){
            setContactCommunityErr("Spritual Path is Required!");
            valid1 = false;
        }

        return valid1;
    }

    const handleJobSubmit = async (event) =>{
        event.preventDefault();
        console.log(jobValid());
        if(jobValid()){
          
            const formData = new FormData();
            formData.append("name", job_name);
            formData.append("spritual_path", community);
            formData.append("phone", job_phone);
            formData.append("type", "job");
            formData.append("message", "");
            try {
                const response = await fetch(`${baseUrl}/enquiry`, {
                  method: "POST",
                  body: formData,
                });
        
                const data = await response.json();
                if (response.ok) {
                  toastr.success(data.message);
                  setJobName("");
                  setJobPhone("");
                  SetCommunity("");
                } else {
                  toastr.error(data.message);
                }
              } catch (error) {
                toastr.error("An error occurred. Please try again later.");
              }
        }

    }

    const handleContactSubmit = async (event) =>{
        event.preventDefault();
        console.log(jobValid());
        if(ContactValid()){
          
            const formData = new FormData();
            formData.append("name", contact_name);
            formData.append("spritual_path", community1);
            formData.append("phone", contact_phone);
            formData.append("type", "contact");
            formData.append("message", message);
            try {
                const response = await fetch(`${baseUrl}/enquiry`, {
                  method: "POST",
                  body: formData,
                });
        
                const data = await response.json();
                if (response.ok) {
                  toastr.success(data.message);
                  setContactName("");
                  setContactPhone("");
                  SetCommunity1("");
                  setMessage("");
                } else {
                  toastr.error(data.message);
                }
              } catch (error) {
                toastr.error("An error occurred. Please try again later.");
              }
        }
    }

    return (
        <>
            <section className="w70 contactus fcs-display m_auto">
                <div className="left_job_bx w50">
                    <h1>Jobs at Satsangirishtey.com</h1>
                    <p>We need employees from every satsangi path to spread our presence and register the people looking for satsangi life partner.
                        If you're following any Satsangi Path then join our team.</p>
                    <h3>Benefits of Joining our Team:</h3>
                    <ul>
                        <li>Use Your Satsangi Network and Earn</li>
                        <li>Earn while Working From Home</li>
                        <li>No Corporate or Job Pressure</li>
                        <li>No Study or Job Experience Required</li>
                    </ul>
                </div>
                <div className="right_job_bx w50">
                    <form action="javascript:void(0)" method="post" id="jobForm" onSubmit={handleJobSubmit}>
                    <div className="card_bx">
                        <label htmlFor="First_Name">
                            <i className="bi bi-person-fill"></i>Name
                        </label>
                        <input
                            type="text"
                            placeholder="Name"
                            name="Name"
                            id="job_name" 
                            value={job_name} onChange={(e)=>{setJobName(e.target.value);setJobNameErr("");}}
                        />
                         <div className="err">
                            {job_name_err}
                         </div>
                    </div>
                    <div className="card_bx">
                        <label htmlFor="Phone">
                            <i className="bi bi-phone-fill"></i>Phone
                        </label>
                        <input
                            type="text"
                            placeholder="Phone"
                            name="Phone"
                            id="job_phone" 
                            value={job_phone} onChange={(e)=>{setJobPhone(e.target.value);setJobPhoneErr("");}}
                        />

                        <div className="err">
                            {job_phone_err}
                         </div>
                         
                    </div>
                    <div className="filter_card" >
                        <label htmlFor="Looking For a">
                            <i className="bi bi-plus-circle-fill"></i> Spritual Path
                        </label>
                        <br />
                        <input
                            type="text"
                            placeholder="Select"
                            value={community}
                            name="job_community" 
                            id="job_community" 
                            onFocus={() => handelFoucs("follower_of_drop_bx2")}
                            onBlur={() => handelFoucsout("follower_of_drop_bx2")} onChange={() => { }}
                        />
                        <i className="bi bi-caret-down-fill"></i>
                        <div className="drop_bx" id="follower_of_drop_bx2">
                            {communityes.map((el, i) => (
                                <li key={i} onClick={() => { SetCommunity(el); setCommunityErr(""); }}>
                                 {el}
                                </li>
                            ))}
                        </div>
                        <div className="text-danger community_err err">{community_err}</div>
                    </div>
                    <div className="card_bx">
                        <button className="reset_btn" type="submit">Submit</button>
                    </div>
                    </form>
                </div>

                <h1 className="head">Contact Us</h1>

                <div className="left_job_bx w50">
                    <form action="javascript:void(0)" method="post" id="contactForm" onSubmit={handleContactSubmit}>
                    <div className="card_bx">
                        <label htmlFor="First_Name">
                            <i className="bi bi-person-fill"></i>Name
                        </label>
                        
                        <input
                            type="text"
                            placeholder="Name"
                            name="Name"
                            id="contact_name" 
                            value={contact_name} onChange={(e)=>{setContactName(e.target.value);setContactNameErr("");}}
                        />
                         <div className="err">
                            {contact_name_err}
                         </div>
                       
                    </div>
                    <div className="card_bx">
                        <label htmlFor="Phone">
                            <i className="bi bi-phone-fill"></i>Phone
                        </label>
                        <input
                            type="text"
                            placeholder="Phone"
                            name="Phone"
                            id="contact_phone" 
                            value={contact_phone} onChange={(e)=>{setContactPhone(e.target.value);setContactPhoneErr("");}}
                        />

                        <div className="err">
                            {contact_phone_err}
                         </div>
                         
                       
                    </div>
                    <div className="card_bx">
                        <label htmlFor="Phone">
                            <i className="bi bi-chat-dots-fill"></i>Message
                        </label>
                        <textarea name="message" id="" placeholder="Message" onChange={(e)=>{setMessage(e.target.value);setMessageErr("")}}>{message}</textarea>
                        <div className="err">
                            {messageErr}
                        </div>
                    </div>
                   
                    <div className="filter_card" >
                        <label htmlFor="Looking For a">
                            <i className="bi bi-plus-circle-fill"></i> Spritual Path
                        </label>
                        <br />
                        <input
                            type="text"
                            placeholder="Select"
                            value={community1}
                            onFocus={() => handelFoucs("follower_of_drop_bx1")}
                            onBlur={() => handelFoucsout("follower_of_drop_bx1")} onChange={() => { }}
                        />
                        <i className="bi bi-caret-down-fill"></i>
                        <div className="drop_bx" id="follower_of_drop_bx1">
                            {communityes.map((el, i) => (
                                <li key={i} onClick={() => { SetCommunity1(el); setContactCommunityErr(""); }}>
                                    {el}
                                </li>
                            ))}
                        </div>
                        <div className="err">{contact_community_err}</div>
                    </div>
                    <div className="card_bx">
                        <button className="reset_btn" type="submit">Submit</button>
                    </div>
                    </form>
                </div>
                <div className="right_job_bx w50 fcc-display" style={{margin: 'auto'}}>
                   <img src="icon/illu_8.png" alt=""style={{width: '80%'}} />
                </div>
            </section>
        </>
    );
};

export default Contactus;
