import './Website/assets/css/utilities.css';
import './Website/assets/css/animation.css';
import './Website/assets/css/style.css';
import './Website/assets/css/media.css';
import Header from './Website/layout/header.jsx';
import Footer from './Website/layout/footer.jsx';

import RouteSet from './Website/Routes/index.jsx';


function App() {
  return (
    <>
      <Header/>
      <RouteSet/>
      <Footer/>
    </>
  );
}

export default App;
