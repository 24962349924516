let baseUrl = process.env.REACT_APP_BACKEND_URL;


export const fetchVisitorCount = async (user_id) => {
 
  try {
    const response = await fetch(`${baseUrl}/fetchVisitorCount`, {
      method: 'POST',
      headers: {
           'Content-Type': 'application/json'
      },
      body: JSON.stringify({ user_id })
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Failed to fetch visior:', error);
    throw error;
  }
};

export const fetchProfiles = async () => {
 
    try {
      const response = await fetch(`${baseUrl}/fetchProfiles`); // Replace with your API endpoint
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to fetch profiles:', error);
      throw error;
    }
  };
  
  export const fetchCommunity = async () => {
    try {
      const response = await fetch(`${baseUrl}/fetchCommunity`); // Replace with your API endpoint
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to fetch community:', error);
      throw error;
    }
  };

  export const fetchReligion = async () => {
    try {
      const response = await fetch(`${baseUrl}/fetchReligion`); // Replace with your API endpoint
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to fetch Religion:', error);
      throw error;
    }
  };

  export const fetchIncomeRange = async () => {
    try {
      const response = await fetch(`${baseUrl}/fetchIncomeRange`); // Replace with your API endpoint
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to fetch Income:', error);
      throw error;
    }
  };
  export const fetchQualification = async () => {
    try {
      const response = await fetch(`${baseUrl}/fetchQualification`); // Replace with your API endpoint
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to fetch qualification:', error);
      throw error;
    }
  };
  export const fetchCountry = async () => {
    try {
      const response = await fetch(`${baseUrl}/fetchCountry`); // Replace with your API endpoint
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to fetch Country:', error);
      throw error;
    }
  };

  export const fetchState = async (country) => {
    
    try {
      const response = await fetch(`${baseUrl}/fetchState`, {
        method: 'POST',
        headers: {
             'Content-Type': 'application/json'
        },
        body: JSON.stringify({ country })
      });
  
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to fetch state:', error);
      throw error;
    }
  };

  export const fetchStateMultiple = async (country) => {
    
    try {
      const response = await fetch(`${baseUrl}/fetchStateMultiple`, {
        method: 'POST',
        headers: {
             'Content-Type': 'application/json'
        },
        body: JSON.stringify({ country })
      });
  
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to fetch state:', error);
      throw error;
    }
  };

  export const fetchCityMultiple = async (country,state) => {
    
    try {
      const response = await fetch(`${baseUrl}/fetchCityMultiple`, {
        method: 'POST',
        headers: {
             'Content-Type': 'application/json'
        },
        body: JSON.stringify({ country,state })
      });
  
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to fetch city:', error);
      throw error;
    }
  };
  
  
  
  export const fetchCity = async (country,state) => {
    
    try {
      const response = await fetch(`${baseUrl}/fetchCity`, {
        method: 'POST',
        headers: {
             'Content-Type': 'application/json'
        },
        body: JSON.stringify({ country,state })
      });
  
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to fetch city:', error);
      throw error;
    }
  };

  export const fetchMaritalStatus = async () => {
    try {
      const response = await fetch(`${baseUrl}/fetchMaritalStatus`); // Replace with your API endpoint
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to fetch Marital Status:', error);
      throw error;
    }
  };

  export const fetchMotherTongue = async () => {
    try {
      const response = await fetch(`${baseUrl}/fetchMotherTongue`); // Replace with your API endpoint
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to fetch Mother Tongue:', error);
      throw error;
    }
  };

  export const fetchHeight = async () => {
    try {
      const response = await fetch(`${baseUrl}/fetchHeight`); // Replace with your API endpoint
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to fetch Height:', error);
      throw error;
    }
  };

  export const fetchData = async (select, table, condition) => {
    try {
    
      const response = await fetch(`${baseUrl}/fetchData`, {
        method: 'POST',
        headers: {
             'Content-Type': 'application/json'
        },
        body: JSON.stringify({ select,table,condition })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to fetch data:', error);
      throw error;
    }
  }

  export const insertData = async (table,data_arr) =>{
    try {
    
      const response = await fetch(`${baseUrl}/insertData`, {
        method: 'POST',
        headers: {
             'Content-Type': 'application/json'
        },
        body: JSON.stringify({ table,data_arr })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
        return data;
      } catch (error) {
        console.error('Failed to insert data:', error);
        throw error;
      }
  }

  export const updateData = async (table,data_arr,condition) =>{
    try {
    
      const response = await fetch(`${baseUrl}/updateDataHelper`, {
        method: 'POST',
        headers: {
             'Content-Type': 'application/json'
        },
        body: JSON.stringify({ table,data_arr,condition })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
     return true;
      } catch (error) {
        console.error('Failed to update data:', error);
        throw error;
      }
  }

  export const fetchNewMatches = async () => {
    try {
      const response = await fetch(`${baseUrl}/fetchNewMatches`); // Replace with your API endpoint
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to fetch Matches:', error);
      throw error;
    }
  };

  export const fetchSuggestedMatches = async () => {
    try {
      const response = await fetch(`${baseUrl}/fetchSuggestedMatches`); // Replace with your API endpoint
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to fetch Matches:', error);
      throw error;
    }
  };


  export const fetchForgetStep1 = async (user_id) => {
 
    try {
      const response = await fetch(`${baseUrl}/fetchForgetStep1`, {
        method: 'POST',
        headers: {
             'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user_id })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to fetch visior:', error);
      throw error;
    }
  };


  export const fetchForgetStep2 = async (user_email) => {
 
    try {
      const response = await fetch(`${baseUrl}/fetchForgetStep2`, {
        method: 'POST',
        headers: {
             'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user_email })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to fetch visior:', error);
      throw error;
    }
  };

 
  
  
