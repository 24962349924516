import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { fetchData,fetchMotherTongue } from "../../helpers/databaseHelpers";


let Partner_preference = () => {
   
    let baseUrl = process.env.REACT_APP_BACKEND_URL;
    const [userDetails, setUserDetails] = useState([]);
    const [userEdit, setUserEdit] = useState(false);
    const [motherTongue,setMotherToungue] = useState([]);
    const [partnerPreference, setPartnerPreference] = useState([]);
    const token = localStorage.getItem("authToken");
  
    let navigate = useNavigate();
    if (!token) {
      console.log("No token found. Redirecting to login.");
      navigate("/");
    }

    useEffect(() => {
        const loadDetails = async () => {
            try {
                const whereObj = { token: token };
                const userData = await fetchData("*", "users", whereObj);
                setUserDetails(userData[0]);
            } catch (error) {
                console.error("Error loading user details:", error);
            }
        };
        loadDetails();
    }, [token]);

    useEffect(() => {
        const loadLanguage = async () => {
            if (userDetails.uniquecode) {
                try {
                    const motherTongueData = await fetchMotherTongue();
                    setMotherToungue(motherTongueData);
                } catch (error) {
                    console.error("Error loading images:", error);
                }
            }
        };
        loadLanguage();
    }, [userDetails.uniquecode, userEdit]);



    useEffect(() => {
      const loadpref = async () => {
        try {
          if (userDetails.uniquecode) {
            let user_id = userDetails.uniquecode;
            const whereObj1 = { user_id: user_id };
  
            const pref = await fetchData("*", "partner_preferences", whereObj1);
            if(pref){
            setPartnerPreference(pref[0]);
          }
        }
        } catch (error) {
          console.error("Error loading :", error);
        }
      };
      loadpref();
    }, [userDetails.uniquecode,userEdit]);
 

 

    return (
        <>
            <section className="w60  m_auto partner_pre">
              
                <div className="left_right_bx fcs-display w100">
                    <button type="button" id="editPref" onClick={()=>{navigate('/myprofile#partnerPref')}}>Edit</button>
                    <div className="left_bx_pre">
                        <h3>Basic Details</h3>
                        <div className="card">
                            <label htmlFor="Age">Age</label>
                            <div className="content">
                                <li>{partnerPreference.start_age} to {partnerPreference.end_age}</li>
                            </div>
                        </div>
                        <div className="card">
                            <label htmlFor="Height range">Height range</label>
                            <div className="content">
                                <li>{partnerPreference.start_height_lable} to {partnerPreference.end_height_lable}</li>
                            </div>
                        </div>
                        <div className="card">
                            <label htmlFor="Matrial Status">Matrial Status</label>
                            <div className="content">

                            {partnerPreference.marital_status
                        ? partnerPreference.marital_status
                            .split(",")
                            .map((marital_status, index) => <li key={index}>{marital_status}</li>)
                        : null}
                            
                            </div>
                        </div>
                        <div className="card">
                            <label htmlFor="Profile With Children">Profile With Children</label>
                            <div className="content">
                                <li>{partnerPreference.profile_with_children}</li>
                            </div>
                        </div>
                    </div>
                    <div className="right_bx_pre">
                        <h3>Spiritual Details</h3>
                        <div className="card">
                            <label htmlFor="Spiritual Path">Spiritual Path</label>
                            <div className="content">
                            {partnerPreference.community
                        ? partnerPreference.community
                            .split(",")
                            .map((communit, index) => <li key={index}>{communit}</li>)
                        : null}
                            </div>
                        </div>
                        <div className="card">
                            <label htmlFor="Religion">Religion</label>
                            <div className="content">
                            {partnerPreference.religion
                        ? partnerPreference.religion
                            .split(",")
                            .map((rel, index) => <li key={index}>{rel}</li>)
                        : null}
                            </div>
                        </div>
                        <div className="card">
                            <label htmlFor="Mother Tongue">Mother Tongue</label>
                            <div className="content">
                            {partnerPreference.mother_tongue
                        ? partnerPreference.mother_tongue
                            .split(",")
                            .map((mothe, index) => <li key={index}>{mothe}</li>)
                        : null}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


export default Partner_preference;