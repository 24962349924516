import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import "toastr/build/toastr.min.css";
import toastr from "toastr";

import
{
  fetchProfiles,
  fetchCommunity,
  fetchReligion,
  fetchIncomeRange,
  fetchQualification,
  fetchCountry,
  fetchState,
  fetchCity,
  fetchMaritalStatus,
  fetchMotherTongue,
  fetchHeight,
  fetchData,
  fetchForgetStep1
} from "../../helpers/databaseHelpers";



let Header = () =>
{
  let baseUrl = process.env.REACT_APP_BACKEND_URL;
  const [ otp, setOtp ] = useState( "" );
  const handleChange = ( e ) =>
  {
    const otpRegex = /^\d{0,4}$/;
    if ( !otpRegex.test( e.target.value ) )
    {
      return;
    }
    setOtp( e.target.value );
  };

  const [ selectedImage, setSelectedImage ] = useState( "img/user.jpg" );
  const [ fileData, setFileData ] = useState( "" );
  const [ username, setUsername ] = useState( "" );
  const [ password, setPassword ] = useState( "" );
  const [ usernameError, setUsernameError ] = useState( "" );
  const [ passError, setPassError ] = useState( "" );
  const [ loginError, setLoginError ] = useState( "" );
  const [ signupError, setSignupError ] = useState( "" );

  //sign up state
  const [ activeProfile, setActiveProfile ] = useState( "" );
  const [ profileDetails, setProfileDetails ] = useState( {
    gender: "",
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    followerOf: "",
    activeReligion: "",
    email: "",
    phone: "",
    city: "",
    livesWithHisParents: "",
    highestQualification: "",
    designation: "",
    password: "",
    company: "",
    income: "",
    aboutMyProfile: "",
    profilePhoto: "",
  } );

  const setProfileFor = ( profile ) =>
  {
    setActiveProfile( profile );
    // Optionally, you can reset profileDetails or load specific details for the selected profile
    setProfileDetails( {
      gender: "",
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      email: "",
      phone: "",
      city: "",
      highestQualification: "",
      designation: "",
      password: "",
      company: "",
      income: "",
      aboutMyProfile: "",
    } );
  };

  const validateEmail = ( email ) =>
  {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if ( !emailRegex.test( email ) )
    {
      setSignupError( 'Please enter a valid email address.' );
      return;
    } else
    {
      setSignupError( '' );
      return true;
    }
  };
  const validateAge = ( dob ) =>
  {
    const currentDate = new Date();
    const birthDate = new Date( dob );
    const ageDifference = currentDate.getFullYear() - birthDate.getFullYear();
    const monthDifference = currentDate.getMonth() - birthDate.getMonth();
    const dayDifference = currentDate.getDate() - birthDate.getDate();

    if (
      ageDifference < 18 ||
      ( ageDifference === 18 && ( monthDifference < 0 || ( monthDifference === 0 && dayDifference < 0 ) ) )
    )
    {
      setSignupError( "You must be at least 18 years old." );
      return;
    } else
    {
      setSignupError( "" );
      return true;
    }
  };
  const handleInputChange = ( e ) =>
  {
    const { name, value } = e.target;
    setProfileDetails( ( prevDetails ) => ( {
      ...prevDetails,
      [ name ]: value,
    } ) );

    if ( name === "dateOfBirth" )
    {
      validateAge( value );
    }

    if ( name === 'email' )
    {
      validateEmail( value );
    }
  };

  const handleGenderSelect = ( gender ) =>
  {
    setProfileDetails( ( prevDetails ) => ( {
      ...prevDetails,
      gender: gender,
    } ) );
  };

  const [ profiles, setProfiles ] = useState( [] );
  const [ error, setError ] = useState( "" );

  useEffect( () =>
  {
    const loadProfiles = async () =>
    {
      try
      {
        const profilesData = await fetchProfiles();
        const arr = [];
        profilesData.map( ( prof ) =>
        {
          arr.push( prof.name );
        } );
        setProfiles( arr );
      } catch ( error )
      {
        console.error( "Error loading profiles:", error );
        setError( "Failed to load profiles" );
      }
    };

    loadProfiles();
  }, [] );

  const handleImageChange = async ( event ) =>
  {
    const file = event.target.files[ 0 ];

    setFileData( file );
    const reader = new FileReader();
    reader.onloadend = () =>
    {
      setSelectedImage( reader.result );
    };

    if ( file )
    {
      reader.readAsDataURL( file );
    }
  };

  let [ scroll_s_bx, setScroll_s_bx ] = useState( "" );

  useEffect( () =>
  {
    if ( scroll_s_bx == 0 )
    {
      document.getElementsByClassName( "one_sign_up" )[ 0 ].scrollLeft = 0;
    } else if ( scroll_s_bx == 1 )
    {
      document.getElementsByClassName( "one_sign_up" )[ 0 ].scrollLeft = 360;
    } else if ( scroll_s_bx == 2 )
    {
      document.getElementsByClassName( "one_sign_up" )[ 0 ].scrollLeft = 735;
    } else if ( scroll_s_bx == 3 )
    {
      document.getElementsByClassName( "one_sign_up" )[ 0 ].scrollLeft = 1085;
    } else if ( scroll_s_bx == 4 )
    {
      document.getElementsByClassName( "one_sign_up" )[ 0 ].scrollLeft = 1455;
    } else if ( scroll_s_bx == 5 )
    {
      document.getElementsByClassName( "one_sign_up" )[ 0 ].scrollLeft = 1805;
    } else if ( scroll_s_bx == 6 )
    {
      document.getElementsByClassName( "one_sign_up" )[ 0 ].scrollLeft = 2145;
    } else if ( scroll_s_bx == 7 )
    {
      document.getElementsByClassName( "one_sign_up" )[ 0 ].scrollLeft = 2545;
    }
  }, [ scroll_s_bx ] );

  const [ communityes, setCommunityes ] = useState( [] );
  useEffect( () =>
  {
    const loadCommunity = async () =>
    {
      try
      {
        const communityData = await fetchCommunity();
        const communityess = [];
        communityData.map( ( comu ) =>
        {
          communityess.push( comu.name );
        } );
        setCommunityes( communityess );
      } catch ( error )
      {
        console.error( "Error loading community:", error );
        setError( "Failed to load community" );
      }
    };

    loadCommunity();
  }, [] );

  let [ community, SetCommunity ] = useState( "" );

  //income range
  const [ incomeRange, setIncomeRange ] = useState( [] );
  useEffect( () =>
  {
    const loadIncomeRange = async () =>
    {
      try
      {
        const incomeData = await fetchIncomeRange();
        const arr_2 = [];
        incomeData.map( ( inc ) =>
        {
          arr_2.push( inc.lable );
        } );
        setIncomeRange( arr_2 );
      } catch ( err )
      {
        console.error( "Error loading income:", err );
        setError( "Failed to load income" );
      }
    };

    loadIncomeRange();
  }, [] );

  const [ income, setIncome ] = useState( incomeRange[ 0 ] );
  //income range end

  //qualificaion
  const [ highQualificaion, setHighQualificaion ] = useState( [] );
  useEffect( () =>
  {
    const loadQualificaiont = async () =>
    {
      try
      {
        const qualificaionData = await fetchQualification();
        const arr_3 = [];
        qualificaionData.map( ( qual ) =>
        {
          arr_3.push( qual.name );
        } );
        setHighQualificaion( arr_3 );
      } catch ( err )
      {
        console.error( "Error loading qualification:", err );
        setError( "Failed to load qualification" );
      }
    };

    loadQualificaiont();
  }, [] );
  //  console.log(highQualificaion);
  const [ qualification, setQualification ] = useState( highQualificaion[ 0 ] );
  //qualification

  //marital_status
  const [ maritalStatuses, setMaritalStatuses ] = useState( [] );
  useEffect( () =>
  {
    const loadMaritalStatus = async () =>
    {
      try
      {
        const maritalStatusData = await fetchMaritalStatus();
        const maritalStatusesNames = maritalStatusData.map( ( sta ) => sta.name );
        setMaritalStatuses( maritalStatusesNames );
      } catch ( err )
      {
        console.error( "Error loading marital status:", err );
        setError( "Failed to load marital status" );
      }
    };
    loadMaritalStatus();
  }, [] );
  const [ maritalStatus, setMaritalStatus ] = useState( maritalStatuses[ 0 ] );
  //

  // fetchHeight
  const [ heights, setHeights ] = useState( [] );
  const [ heightInchs, setHeightInchs ] = useState( [] );
  useEffect( () =>
  {
    const loadHeights = async () =>
    {
      try
      {
        const heightsData = await fetchHeight();
        const heightsNames = heightsData.map( ( hgt ) => hgt.lable );
        const heightsInchNames = heightsData.map( ( hgt ) => hgt.inch );
        setHeights( heightsNames );
        setHeightInchs( heightsInchNames );
      } catch ( err )
      {
        console.error( "Error loading height:", err );
        setError( "Failed to load height" );
      }
    };
    loadHeights();
  }, [] );
  const [ height, setHeight ] = useState( heights[ 0 ] );
  const [ heightInch, setHeightInch ] = useState( heightInchs[ 0 ] );
  //

  //marital_status
  const [ motherTongues, setMotherTongues ] = useState( [] );
  useEffect( () =>
  {
    const loadMotherTongue = async () =>
    {
      try
      {
        const motherTongueData = await fetchMotherTongue();
        const motherTongueNames = motherTongueData.map( ( stas ) => stas.name );
        setMotherTongues( motherTongueNames );
      } catch ( err )
      {
        console.error( "Error loading mother tongue:", err );
        setError( "Failed to load mother tongue" );
      }
    };
    loadMotherTongue();
  }, [] );
  const [ motherTongue, setMotherTongue ] = useState( motherTongues[ 0 ] );
  //country

  //country
  const [ countries, setCountries ] = useState( [] );
  useEffect( () =>
  {
    const loadCountry = async () =>
    {
      try
      {
        const countryData = await fetchCountry();
        const arr_4 = [];
        countryData.map( ( countr ) =>
        {
          arr_4.push( countr.country );
        } );
        setCountries( arr_4 );
      } catch ( err )
      {
        console.error( "Error loading country:", err );
        setError( "Failed to load country" );
      }
    };

    loadCountry();
  }, [] );
  const [ country, setCountry ] = useState( countries[ 0 ] );
  //country

  //states
  const [ states, setStates ] = useState( [] );
  const [ state, setState ] = useState( "" );

  async function changCountry ( country )
  {
    setState( "" );
    setCity( "" );
    loadState( country );
  }
  const loadState = async ( country ) =>
  {
    try
    {
      const stateData = await fetchState( country );
      const stateNames = stateData.map( ( stat ) => stat.state );
      setStates( stateNames );
    } catch ( err )
    {
      console.error( "Error loading state:", err );
      setError( "Failed to load state" );
    }
  };

  //states

  const [ cities, setCities ] = useState( [] );
  const [ city, setCity ] = useState( "" );

  const loadCity = async ( country, state ) =>
  {
    try
    {
      const cityData = await fetchCity( country, state );
      const cityNames = cityData.map( ( cit ) => cit.city );
      setCities( cityNames );
    } catch ( err )
    {
      console.error( "Error loading city:", err );
      setError( "Failed to load city" );
    }
  };

  async function changState ( country, state )
  {
    setCity( "" );
    loadCity( country, state );
  }

  const [ religions, SetReligions ] = useState( [] );
  useEffect( () =>
  {
    const loadReligion = async () =>
    {
      try
      {
        const religionData = await fetchReligion();
        const arr_1 = [];
        religionData.map( ( rel ) =>
        {
          arr_1.push( rel.name );
        } );
        SetReligions( arr_1 );
      } catch ( error )
      {
        console.error( "Error loading religion:", error );
        setError( "Failed to load religion" );
      }
    };

    loadReligion();
  }, [] );

  let [ religion, Setreligion ] = useState( religions[ 0 ] );
  let [ lives, Setlives ] = useState( "Yes" );
  const [ userId, setUserId ] = useState( "" );
  function handelFoucs ( id )
  {
    document.getElementById( id ).classList.add( "drop_bx_active" );
  }

  function handelFoucsout ( id )
  {
    let el = document.getElementById( id );
    if ( el )
    {
      setTimeout( () =>
      {
        document.getElementById( id ).classList.remove( "drop_bx_active" );
      }, 300 );
    }
  }

  let [ nav_state, setNavstate ] = useState( "dashboard" );

  const navigate = useNavigate();

  const handleLogout = ( e ) =>
  {
    localStorage.removeItem( "authToken" );
    handleReset( e );
    toastr.success( "Logout successful." );
    navigate( "/" );
  };


  const handleReset = async ( event ) =>
  {
    event.preventDefault();
    const formData = new FormData();
    formData.append( "uniquecode", userId );
    console.log( userId )
    try
    {
      const response = await fetch( `${ baseUrl }/reset`, {
        method: "POST",
        body: formData,
      } );
      const data = await response.json();
      if ( data.status )
      {
        window.location.reload();
      } else
      {
        toastr.error( "Failed to reset. Please try again." );
      }
    } catch ( error )
    {
      toastr.error( "An error occurred. Please try again later." );
    }
  };



  useEffect( () =>
  {
    // Check if the JWT token is available in localStorage
    const token = localStorage.getItem( "authToken" );
    if ( !token )
    {
      // Redirect to dashboard if token is found
      navigate( "/" );
    }
  }, [] );

  const handleUsername = ( e ) =>
  {
    setUsername( e.target.value );
    if ( usernameError )
    {
      setUsernameError( "" );
    }
  };

  const handlePassword = ( e ) =>
  {
    setPassword( e.target.value );
    if ( passError )
    {
      setPassError( "" );
    }
  };

  const handleEditPhone = () =>
  {
    document.getElementById( "verifyOTpDiv" ).style.display = "none";
    document.getElementById( "sendOtpDiv" ).style.display = "unset";

  }
  const handleSendOtp = async ( email, phone, country ) =>
  {
    document.getElementById( "sentOTPbtn" ).style.display = "none";
    document.getElementById( "sendOTPLoad" ).style.display = "unset";
    let _url;

    if ( country !== "India" )
    {
      _url = `${ baseUrl }/sendOtpWithEmail`;
    } else
    {
      _url = `${ baseUrl }/sendSms`;
    }

    try
    {
      const response = await fetch( _url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify( { email, phone } ),
      } );

      document.getElementById( "sentOTPbtn" ).style.display = "unset";
      document.getElementById( "sendOTPLoad" ).style.display = "none";
      const data = await response.json();

      if ( response.ok )
      {
        toastr.success( data.message || "Otp sent successfully." );
        document.getElementById( "verifyOTpDiv" ).style.display = "unset";
        document.getElementById( "sendOtpDiv" ).style.display = "none";

      } else
      {
        toastr.error( data.message || "Otp send failed." );
        document.getElementById( "sentOTPbtn" ).style.display = "unset";
        document.getElementById( "sendOTPLoad" ).style.display = "none";
      }
    } catch ( error )
    {
      toastr.error( error.message || "Otp send failed." );
      document.getElementById( "sentOTPbtn" ).style.display = "unset";
      document.getElementById( "sendOTPLoad" ).style.display = "none";
    }
  };
  const handleReSendOtp = async ( email, phone, country ) =>
  {
    document.getElementById( "re_send" ).style.display = "none";
    document.getElementById( "resend_load" ).style.display = "unset";
    let _url;

    if ( country !== "India" )
    {
      _url = `${ baseUrl }/sendOtpWithEmail`;
    } else
    {
      _url = `${ baseUrl }/sendSms`;
    }

    try
    {
      const response = await fetch( _url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify( { email, phone } ),
      } );

      document.getElementById( "re_send" ).style.display = "unset";
      document.getElementById( "resend_load" ).style.display = "none";
      const data = await response.json();

      if ( response.ok )
      {
        toastr.success( data.message || "Otp sent successfully." );
      } else
      {
        toastr.error( data.message || "Otp send failed." );
      }
    } catch ( error )
    {
      toastr.error( error.message || "Otp send failed." );
    }
  };

  const handleLogin = async ( event ) =>
  {
    event.preventDefault();

    if ( !username )
    {
      setUsernameError( "Username is Required." );
      return;
    }
    if ( !password )
    {
      setPassError( "Password is Required." );
      return;
    }

    try
    {
      const response = await fetch( `${ baseUrl }/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify( { username, password } ),
      } );

      const data = await response.json();

      if ( response.ok )
      {
        setUserId( data.userId );
        localStorage.setItem( "authToken", data.token );
        toastr.success( data.message || "Login successful." );
        setTimeout( function ()
        {
          navigate( "/dashboard" );
          let cont = document.getElementsByClassName( 'container' )[ 0 ];
          if ( cont )
          {
            cont.classList.remove( 'container_active' );
            toastr.remove();
          }
        }, 1000 )
      } else
      {
        setLoginError( data.message || "Login failed. Please try again." );
      }
    } catch ( error )
    {
      console.error( "Login error:", error );
      setLoginError( "An error occurred. Please try again later." );
    }
  };

  const handleRegister = async ( event ) =>
  {
    event.preventDefault();

    if ( !otp )
    {
      setSignupError( "Otp is Required." );
    } else
    {
      document.getElementById( "registerLoadBTN" ).style.display = "flex";
      document.getElementById( "registerSubmitBTN" ).style.display = "none";
      // Create a new FormData object
      const formData = new FormData();

      formData.append( "activeProfile", activeProfile );
      formData.append( "gender", profileDetails.gender );
      formData.append( "firstName", profileDetails.firstName );
      formData.append( "lastName", profileDetails.lastName );
      formData.append( "height", height );
      formData.append( "height_in_inch", heightInch );
      formData.append( "dateOfBirth", profileDetails.dateOfBirth );

      formData.append( "community", community );
      formData.append( "religion", religion );
      formData.append( "marital_status", maritalStatus );

      formData.append( "mother_tongue", motherTongue );

      formData.append( "email", profileDetails.email );
      formData.append( "phone", profileDetails.phone );

      formData.append( "country", country );
      formData.append( "state", state );
      formData.append( "city", city );

      formData.append( "highestQualification", qualification );
      formData.append( "company", profileDetails.company );
      formData.append( "income", income );
      formData.append( "designation", profileDetails.designation );
      formData.append( "password", profileDetails.password );

      formData.append( "photo", fileData );
      formData.append( "aboutMyProfile", profileDetails.aboutMyProfile );
      formData.append( "otp", otp );
      // for (let v of formData.entries()) {
      //   console.log(v);
      // }
      try
      {
        const response = await fetch( `${ baseUrl }/register`, {
          method: "POST",
          body: formData,
        } );

        const data = await response.json();
        if ( response.ok )
        {

          document.getElementById( "registerLoadBTN" ).style.display = "none";
          document.getElementById( "registerSubmitBTN" ).style.display = "flex";
          toastr.success( data.message || "Registration successful." );
          setTimeout( function ()
          {
            toastr.success( "Registration successful. Check Your Email." );
            document
              .getElementsByClassName( "container" )[ 0 ]
              .classList.add( "container_active" );
            document
              .getElementsByClassName( "container" )[ 0 ]
              .classList.add( "container_active" );
            document.getElementsByClassName(
              "sign_up_steps"
            )[ 0 ].style.opacity = 0;
          }, 1000 );
        } else
        {
          document.getElementById( "registerLoadBTN" ).style.display = "none";
          document.getElementById( "registerSubmitBTN" ).style.display = "flex";
          setSignupError(
            data.message || "Registration failed. Please try again."
          );
          toastr.error(
            data.message || "Registration failed. Please try again."
          );
        }
      } catch ( error )
      {
        document.getElementById( "registerLoadBTN" ).style.display = "none";
        document.getElementById( "registerSubmitBTN" ).style.display = "flex";
        toastr.error( "An error occurred. Please try again later." );
        setSignupError( "An error occurred. Please try again later." );
      }
    }
  };

  let scrollOffOn = () =>
  {
    document.getElementsByClassName( 'one_sign_up' )[ 0 ].style.overflowX = 'auto';
    setTimeout( () =>
    {
      document.getElementsByClassName( 'one_sign_up' )[ 0 ].style.overflowX = 'hidden';
    }, 1500 );
  }

  const handleContinue = ( num ) =>
  {
    if ( num === 1 )
    {
      if ( !activeProfile )
      {
        setSignupError( "Please Select Profile." );
      }
      if ( !profileDetails.gender )
      {
        setSignupError( "Please Select Gender." );
      }

      if ( !activeProfile && !profileDetails.gender )
      {
        setSignupError( "Please Select Profile & Gender." );
      }
      if ( activeProfile && profileDetails.gender )
      {
        setScroll_s_bx( 1 );
        setSignupError( "" );
        scrollOffOn();
      }
    }

    if ( num === 2 )
    {
      if (
        profileDetails.firstName &&
        profileDetails.lastName &&
        profileDetails.dateOfBirth && validateAge( profileDetails.dateOfBirth )
      )
      {
        setScroll_s_bx( 2 );
        setSignupError( "" );
        scrollOffOn();
      } else if ( !profileDetails.firstName )
      {
        setSignupError( "Please Fill First Name." );
      } else if ( !profileDetails.lastName )
      {
        setSignupError( "Please Fill Last Name." );
      } else if ( !profileDetails.dateOfBirth )
      {
        setSignupError( "Please Fill Date of Birth." );
      }
    }

    if ( num === 3 )
    {
      if ( community && religion && maritalStatus )
      {
        setScroll_s_bx( 3 );
        setSignupError( "" );
        scrollOffOn();
      } else if ( !community )
      {
        setSignupError( "Please Select Follower of." );
      } else if ( !religion )
      {
        setSignupError( "Please Select Religion." );
      } else if ( !maritalStatus )
      {
        setSignupError( "Please Select Marital Status." );
      }
    }

    if ( num === 4 )
    {
      if ( country && state && city )
      {
        setScroll_s_bx( 4 );
        setSignupError( "" );
        scrollOffOn();
      } else if ( !country )
      {
        setSignupError( "Please Select Country." );
      } else if ( !state )
      {
        setSignupError( "Please Select State." );
      } else if ( !city )
      {
        setSignupError( "Please Select City." );
      }
    }

    if ( num === 5 )
    {
      if ( !motherTongue )
      {
        setSignupError( "Please Select Mother tongue." );
      } else if ( country != "India" && !validateEmail( profileDetails.email ) )
      {
        setSignupError( "Please enter a valid email address." );
      } else if ( country == "India" && !profileDetails.phone )
      {
        setSignupError( "Please Fill Phone." );
      } else if ( !profileDetails.phone )
      {
        setSignupError( "Please Fill Phone." );
      } else
      {
        setScroll_s_bx( 5 );
        setSignupError( "" );
        scrollOffOn();
      }
    }

    if ( num === 6 )
    {
      if (
        qualification &&
        profileDetails.designation &&
        profileDetails.company &&
        income
      )
      {
        setScroll_s_bx( 6 );
        setSignupError( "" );
        scrollOffOn();
      } else if ( !qualification )
      {
        setSignupError( "Please Fill Highest Qualification." );
      } else if ( !profileDetails.company )
      {
        setSignupError( "Please Fill Company." );
      } else if ( !income )
      {
        setSignupError( "Please Fill Income." );
      } else if ( !profileDetails.designation )
      {
        setSignupError( "Please Fill Designation." );
      }
    }

    if (num === 7) {
      if (
        profileDetails.aboutMyProfile &&
        fileData &&
        profileDetails.password &&
        profileDetails.password.length > 8
      ) {
        setScroll_s_bx(7);
        setSignupError("");
        scrollOffOn();
      } else if (!profileDetails.aboutMyProfile) {
        setSignupError("Please Fill About My Profile.");
      } else if (!profileDetails.password) {
        setSignupError("Please Fill Password.");
      } else if (profileDetails.password.length <= 8) {
        setSignupError("Password must be at least 8 characters.");
      } else if (!fileData) {
        setSignupError("Please Select Image.");
      }
    }
    
  };



  const { pathname } = useLocation();

  useEffect( () =>
  {

    const navLinks = document.querySelectorAll( '.nav_links li' );


    navLinks.forEach( el => el.style.border = 'none' );

    navLinks.forEach( el =>
    {
      el.querySelector( 'a' ).classList.remove( 'active_nav_links' );
      if ( el.querySelector( 'a' ).getAttribute( 'href' ) === pathname )
      {
        el.querySelector( 'a' ).classList.add( 'active_nav_links' );
      }
    } );
  }, [ pathname ] );


  const [ showPassword, setShowPassword ] = useState( false );

  const togglePasswordVisibility = ( element ) =>
  {
    if ( element.classList.contains( 'bi-eye-slash-fill' ) )
    {
      element.classList.remove( 'bi-eye-slash-fill' );
      element.classList.add( 'bi-eye-fill' );
    } else
    {
      element.classList.remove( 'bi-eye-fill' );
      element.classList.add( 'bi-eye-slash-fill' );
    }
  };



  // Forget Start 
  // 1 Step 

  let [ forget_username, SetForgetUserName ] = useState( '' );
  let [ forgotEmailOtp, SetforgotEmailOtp ] = useState( '' );
  let [ mainPassword, setMainPassword ] = useState( '' );
  let [ mainPassword_re, setMainPassword_re ] = useState( '' );
  let forgot1Step = () =>
  {
    document.getElementById( 'error_forget_1' ).classList.add( 'error_n' );
    if ( forget_username != '' )
    {

      const forget_1_step = async () =>
      {
        try
        {
          const forgetUserData = await fetchForgetStep1( forget_username );
          if ( forgetUserData )
          {
            if ( forgetUserData != 'User Not Found' )
            {
              document.getElementById( 'error_forget_1' ).classList.add( 'error_g' );
              document.getElementById( 'error_forget_1' ).innerHTML = `<i className="bi bi-check-circle-fill"></i> ${ forgetUserData[ 0 ].email }`;
              setTimeout( () =>
              {
                document.getElementsByClassName( "container" )[ 2 ].classList.add( "container_active" )
              }, 1500 );

              // User Found Then Send Email

              fetch( `${ baseUrl }/sendOtpWithEmail`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify( { email: forgetUserData[ 0 ].email, phone: forgetUserData[ 0 ].username } ),
              } ).then( response =>
              {
                if ( !response.ok )
                {
                  throw new Error( "Network response was not ok" );
                }
                return response.json();
              } )
                .then( data =>
                {
                  // console.log( "OTP sent successfully New:", data );
                  document.getElementById( 'sentOtpInfo' ).innerHTML = `OTP has been sent to +91 ${ forgetUserData[ 0 ].username.slice( 0, 4 ) }XXXXXX <br /> & ${ forgetUserData[ 0 ].email.slice( 0, 2 ) }XXXXXX@gmail.com`;

                  // Timer Start 
                  let timeLeft = 100;
                  let timerInterval;
                  timerInterval = setInterval( function ()
                  {
                    timeLeft--;
                    document.getElementById( "timer" ).innerHTML = `You Can Entered OTP in ${ timeLeft } seconds`;

                    if ( timeLeft <= 0 )
                    {
                      clearInterval( timerInterval );
                      timeLeft = "Time's up!";
                      setTimeout( () =>
                      {
                        SetforgotEmailOtp('');
                        document.getElementById('error_forget_1').className = 'error_n';
                        document.getElementById('otp_submit_error').className = 'error_n';
                        document.getElementById('error_forget_3').className = 'error_n';
                        document.getElementsByClassName( "container" )[ 2 ].classList.remove( "container_active" )
                      }, 1500 );
                    }
                  }, 1000 );

                  // Timer End
                } )
                .catch( error =>
                {
                  console.error( "There was a problem with the fetch operation:", error );
                } );

            } else
            {
              document.getElementById( 'error_forget_1' ).classList.add( 'error_r' );
              document.getElementById( 'error_forget_1' ).innerHTML = `<i className="bi bi-x-circle-fill"></i> User Not Found`;
            }
          }
        } catch ( error )
        {
          // console.error("Error loading community:", error);
          setError( "Failed to load community" );
        }
      };

      forget_1_step();

    } else
    {
      document.getElementById( 'error_forget_1' ).classList.add( 'error_r' );
      document.getElementById( 'error_forget_1' ).innerHTML = `<i className="bi bi-x-circle-fill"></i> Enter Vaild Username / Phone`;
    }
  }


  // Forget Step 2 Start

 

  let forgotOtpVerify = () =>
  {
    if ( forgotEmailOtp != '' )
    {

      fetch( `${ baseUrl }/fetchForgetStep2`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify( { email: forget_username } ),
      } ).then( response =>
      {
        if ( !response.ok )
        {
          throw new Error( "Network response was not ok" );
        }
        return response.json();
      } )
        .then( data =>
        {

          if ( data[ 0 ].otp == forgotEmailOtp )
          {
            document.getElementById( 'otp_submit_error' ).classList.add( 'error_g' )
            document.getElementById( 'otp_submit_error' ).innerHTML = `<i className="bi bi-check-circle-fill"></i> Otp Matched`;
            setTimeout( () =>
            {
              document.getElementsByClassName( "container" )[ 3 ].classList.add( "container_active" )
            }, 1500 );
          } else
          {
            document.getElementById( 'otp_submit_error' ).classList.add( 'error_r' )
            document.getElementById( 'otp_submit_error' ).innerHTML = `<i className="bi bi-x-circle-fill"></i> Otp Not Match`;
          }
        } )
        .catch( error =>
        {
          console.error( "There was a problem with the fetch operation:", error );
        } );
    } else
    {
      document.getElementById( 'otp_submit_error' ).classList.add( 'error_r' )
      document.getElementById( 'otp_submit_error' ).innerHTML = `<i className="bi bi-x-circle-fill"></i> Enter Vaild OTP`;
    }
  }

  // Forget Step 2 End 


  // Forget Step 3 Start


  let forgotPasswordSet = () =>
  {
    if ( mainPassword != '' && mainPassword_re != '' && ( mainPassword == mainPassword_re ) )
    {
      fetch( `${ baseUrl }/fetchForgetStep3`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify( { 'password': mainPassword, 'email': forget_username } ),
      } ).then( response =>
      {
        if ( !response.ok )
        {
          throw new Error( "Network response was not ok" );
        }
        return response.json();
      } )
        .then( data =>
        {
  
          document.getElementById( 'error_forget_3' ).classList.add( 'error_g' );
          document.getElementById( 'error_forget_3' ).innerHTML = `<i className="bi bi-check-circle-fill"></i> Password Matched`;

          if ( data == 1 )
          {
            document
              .getElementsByClassName( "container" )[ 0 ]
              .classList.remove( "container_active" );
            document
              .getElementsByClassName( "container" )[ 1 ]
              .classList.remove( "container_active" );
            document
              .getElementsByClassName( "container" )[ 2 ]
              .classList.remove( "container_active" );
            document
              .getElementsByClassName( "container" )[ 3 ]
              .classList.remove( "container_active" );
            document
              .getElementsByClassName( "container" )[ 4 ]
              .classList.add( "container_active" );
            setTimeout( () =>
            {
              SetForgetUserName(''); SetforgotEmailOtp(''); setMainPassword(''); setMainPassword_re('');
              document.getElementById('error_forget_1').className = 'error_n';
              document.getElementById('otp_submit_error').className = 'error_n';
              document.getElementById('error_forget_3').className = 'error_n';
              document
                .getElementsByClassName( "container" )[ 4 ]
                .classList.remove( "container_active" )
            }, 3000 )
          } else
          {
            document.getElementById( 'error_forget_3' ).classList.add( 'error_r' );
            document.getElementById( 'error_forget_3' ).innerHTML = `<i className="bi bi-x-circle-fill"></i> Network Failed`;
          }
        } )
        .catch( error =>
        {
          console.error( "There was a problem with the fetch operation:", error );
        } );
    } else
    {
      document.getElementById( 'error_forget_3' ).classList.add( 'error_r' );
      document.getElementById( 'error_forget_3' ).innerHTML = `<i className="bi bi-x-circle-fill"></i> Password Not Match`;
    }
  }

  // Forget Step 3 End

  return (
    <>
      <nav className="fcs-display">
        <Link to="/">
          <img className = 'logoHead' src="/img/logo.png" alt="Satsangiristey" />
        </Link>

        { localStorage.getItem( "authToken" ) ? (
          <div className="nav_links">
            <Link to="/dashboard" onClick={ () => setNavstate( "dashboard" ) }>
              Dashboard
              { nav_state == "dashboard" ? (
                <ul className="dropdown_nav dropdown_nav_dash">
                  <li>
                    <Link to="/dashboard">Dashboard</Link>
                  </li>
                  <li>
                    <Link to="/myprofile">My Profile</Link>
                  </li>
                  <li>
                    <Link to="/myphoto">My Photo</Link>
                  </li>
                  <li>
                    <Link to="/partner_preference">Partner preference</Link>
                  </li>
                  <li>
                    <Link to="/setting">Setting</Link>
                  </li>
                </ul>
              ) : null }
            </Link>
            <Link to="/newmatches" onClick={ () => setNavstate( "matches" ) }>
              Matches
              { nav_state == "matches" ? (
                <ul className="dropdown_nav dropdown_nav_mach">
                  <li>
                    <Link to="/newmatches">New Matches</Link>
                  </li>
                  <li>
                    <Link to="/mymatches">Suggested Matches</Link>
                  </li>
                  <li>
                    <Link to="/shortlisted">Shortlisted Matches</Link>
                  </li>
                </ul>
              ) : null }
            </Link>
            <Link to="/basic_search" onClick={ () => setNavstate( "search" ) }>
              Search
              { nav_state == "search" ? (
                <ul className="dropdown_nav dropdown_nav_seac">
                  <li>
                    <Link to="/basic_search">Basic Search</Link>
                  </li>
                  <li>
                    <Link to="/result">Search Result</Link>
                  </li>
                </ul>
              ) : null }
            </Link>
            <Link to="/received" onClick={ () => setNavstate( "inbox" ) }>
              Inbox
              { nav_state == "inbox" ? (
                <ul className="dropdown_nav dropdown_nav_inbox">
                  <li>
                    <Link to="/received">Received</Link>
                  </li>
                  <li>
                    <Link to="/accepted">Accepted</Link>
                  </li>
                  <li>
                    <Link to="/sent">Sent</Link>
                  </li>
                  <li>
                    <Link to="/cancelled">Cancelled</Link>
                  </li>
                </ul>
              ) : null }
            </Link>
          </div>
        ) : (
          ""
        ) }

        {/* <button className="btn_reset n_padding" id="login_btn" onClick={() => document.getElementsByClassName('container')[0].classList.add('container_active')}>Log in</button> */ }
        { localStorage.getItem( "authToken" ) ? (
          <button
            className="btn_reset n_padding logoutBTN"
            onClick={ handleLogout }
          >
            Logout
          </button>
        ) : (
          <button
            className="btn_reset n_padding"
            id="login_btn"
            onClick={ () =>
              document
                .getElementsByClassName( "container" )[ 0 ]
                .classList.add( "container_active" )
            }
          >
            Log in
          </button>
        ) }
      </nav>

      {/* Login Popup Start  */ }

      <div className="container">
        <div className="screen">
          <div className="screen__content">
            <form className="login" onSubmit={ handleLogin }>
              <h1>Sign in</h1>
              <p>to find your satsangi life partner</p>
              <div className="login__field login__field_first">
                <label htmlFor="username">Username</label>
                <input
                  type="text"
                  className="login__input"
                  placeholder="Phone Number"
                  value={ username }
                  onChange={ handleUsername }
                />
                { usernameError && (
                  <p style={ { color: "red" } }>{ usernameError }</p>
                ) }
              </div>
              <div className="login__field">
                <i
                  className="bi pass_open bi-eye-fill"
                  onClick={ ( e ) =>
                  {
                    const passwordField = document.getElementById( 'password' );
                    togglePasswordVisibility( e.target );
                    passwordField.type = ( passwordField.type === 'password' ) ? 'text' : 'password';
                  } }
                ></i>

                <label htmlFor="Password">Password</label>
                <input
                  type={ showPassword ? 'text' : 'password' }
                  className="login__input"
                  placeholder="Password"
                  value={ password }
                  id="password"
                  onChange={ handlePassword } autoComplete=""
                />
                { passError && (
                  <p style={ { color: "red" } }>{ passError }</p>
                ) }
              </div>
              { loginError && (
                <p style={ { color: "red" } }>{ loginError }</p>
              ) }
              <div className="login_button_forg">
                <button className="button login__submit">
                  <span className="button__text">Sign in</span>
                </button>
                <Link className="forgot_link" onClick={ () => { document.getElementById( 'forgot1_step' ).classList.add( 'container_active' ) } }>Forget Password?</Link>
              </div>
            </form>
            <i
              className="bi bi-x-circle-fill login_exit" id="exit_login"
              onClick={ () =>
                document
                  .getElementsByClassName( "container" )[ 0 ]
                  .classList.remove( "container_active" )
              }
            ></i>
            <div className="register_now_sec">
              <p>Don't have account?</p>
              <button onClick={ () =>
              {
                document.getElementsByClassName(
                  "sign_up_steps"
                )[ 0 ].style.zIndex = 9999999999;
                document.getElementsByClassName(
                  "sign_up_steps"
                )[ 0 ].style.opacity = 1;
                document
                  .getElementsByClassName( "container" )[ 0 ]
                  .classList.remove( "container_active" );
              } }
                style={ { cursor: "pointer" } }>
                Register Now
              </button>
            </div>
          </div>
          {/* <div className="screen__background">
            <span className="screen__background__shape screen__background__shape4"></span>
            <span className="screen__background__shape screen__background__shape3"></span>
            <span className="screen__background__shape screen__background__shape2"></span>
            <span className="screen__background__shape screen__background__shape1"></span>
          </div> */}
        </div>
      </div>

      {/* Login Popup End  */ }



      {/* Forgot 1 Step Popup Start  */ }

      <div className="container" id="forgot1_step">
        <div className="screen">
          <div className="screen__content">
            <form className="login" action="javascript:void(0)">
              <h1>Forgot Password</h1>

              <div className="login__field login__field_first">
                <h5 style={ { textAlign: 'center' } }>Enter Mobile No. or Email ID</h5>
                <p style={ { textAlign: 'center', fontSize: '12px', paddingBottom: '15px' } }>W'll send you an OTP to reset <br /> your password</p>

                <input
                  type="text"
                  className="login__input"
                  placeholder="Phone Number / Email ID"
                  onChange={ ( e ) => { SetForgetUserName( e.target.value ) } }
                />

              </div>


              <div className="login_button_forg">
                <p className="error_n" id="error_forget_1">Username Not Found</p>
                <button className="button login__submit">
                  <span className="button__text" onClick={ () => { forgot1Step(); }
                  }>Send Otp</span>
                </button>
              </div>
            </form>
            <i
              className="bi bi-x-circle-fill login_exit" id="exit_login1"
              onClick={ () =>
                document
                  .getElementsByClassName( "container" )[ 1 ]
                  .classList.remove( "container_active" )
              }
            ></i>
            <div className="register_now_sec">
              <p>Don't have account?</p>
              <button onClick={ () =>
              {
                document.getElementsByClassName(
                  "sign_up_steps"
                )[ 0 ].style.zIndex = 9999999999;
                document.getElementsByClassName(
                  "sign_up_steps"
                )[ 0 ].style.opacity = 1;
                document
                  .getElementsByClassName( "container" )[ 0 ]
                  .classList.remove( "container_active" );
              } }
                style={ { cursor: "pointer" } }>
                Register Now
              </button>
            </div>
          </div>

        </div>
      </div>

      {/* Forgot 1 Step Popup End  */ }

      {/* Forgot 2 Step Popup Start  */ }

      <div className="container" id="forgot1_step">
        <div className="screen">
          <div className="screen__content">
            <form className="login" action="javascript:void(0)">
              <h1>Forgot Password</h1>

              <div className="login__field login__field_first">
                <p style={ { textAlign: 'center', fontSize: '12px' } } id="sentOtpInfo">OTP has been sent to +91 8568XXXXXX <br /> & XXXXXXXXXX@gmail.com</p>

                <h5 style={ { textAlign: 'center', paddingBottom: '15px' } }>Enter OTP</h5>

                <input
                  type="text"
                  className="login__input"
                  placeholder="Enter Otp"
                  value={forgotEmailOtp}
                  onChange={ ( e ) => SetforgotEmailOtp( e.target.value ) }
                />

                <p style={ { textAlign: 'center', fontSize: '11px' } } id='timer'>You Can Entered OTP in 100 seconds</p>
              </div>


              <div className="login_button_forg">
                <p id="otp_submit_error" className="error_n"></p>
                <button className="button login__submit">
                  <span className="button__text" onClick={ () =>
                    forgotOtpVerify()
                  }>Submit</span>
                </button>
              </div>
            </form>
            <i
              className="bi bi-x-circle-fill login_exit" id="exit_login2"
              onClick={ () =>
                document
                  .getElementsByClassName( "container" )[ 2 ]
                  .classList.remove( "container_active" )
              }
            ></i>
            <div className="register_now_sec">
              <p>Don't have account?</p>
              <button onClick={ () =>
              {
                document.getElementsByClassName(
                  "sign_up_steps"
                )[ 0 ].style.zIndex = 9999999999;
                document.getElementsByClassName(
                  "sign_up_steps"
                )[ 0 ].style.opacity = 1;
                document
                  .getElementsByClassName( "container" )[ 0 ]
                  .classList.remove( "container_active" );
              } }
                style={ { cursor: "pointer" } }>
                Register Now
              </button>
            </div>
          </div>

        </div>
      </div>

      {/* Forgot 2 Step Popup End  */ }

      {/* Forgot 3 Step Popup Start  */ }

      <div className="container" id="forgot1_step">
        <div className="screen">
          <div className="screen__content">
            <form className="login" action="javascript:void(0)">
              <h1>Reset Password</h1>

              <div className="login__field login__field_first">
                <i
                  className="bi pass_open bi-eye-fill"
                  onClick={ ( e ) =>
                  {
                    const passwordField = document.getElementById( 'password2' );
                    togglePasswordVisibility( e.target );
                    passwordField.type = ( passwordField.type === 'password' ) ? 'text' : 'password';
                  } }
                ></i>

                <label htmlFor="Password">New password</label>
                <input
                  type={ showPassword ? 'text' : 'password' }
                  className="login__input"
                  placeholder="Password"
                  value={ mainPassword }
                  id="password2"
                  onChange={ ( e ) => setMainPassword( e.target.value ) } autoComplete=""
                />
              </div>
              <div className="login__field">
                <i
                  className="bi pass_open bi-eye-fill"
                  onClick={ ( e ) =>
                  {
                    const passwordField = document.getElementById( 'password3' );
                    togglePasswordVisibility( e.target );
                    passwordField.type = ( passwordField.type === 'password' ) ? 'text' : 'password';
                  } }
                ></i>

                <label htmlFor="Password">Confirm new password</label>
                <input
                  type={ showPassword ? 'text' : 'password' }
                  className="login__input"
                  placeholder="Password"
                  value={ mainPassword_re }
                  id="password3"
                  onChange={ ( e ) => setMainPassword_re( e.target.value ) } autoComplete=""
                />
              </div>


              <div className="login_button_forg">
                <p className="error_n" id="error_forget_3"></p>
                <button className="button login__submit">
                  <span className="button__text"
                    onClick={ () =>
                    {
                      forgotPasswordSet();
                    }
                    }
                  >Confirm</span>
                </button>
              </div>
            </form>
            <i
              className="bi bi-x-circle-fill login_exit" id="exit_login2"
              onClick={ () =>
                document
                  .getElementsByClassName( "container" )[ 3 ]
                  .classList.remove( "container_active" )
              }
            ></i>
            <div className="register_now_sec">
              <p>Don't have account?</p>
              <button onClick={ () =>
              {
                document.getElementsByClassName(
                  "sign_up_steps"
                )[ 0 ].style.zIndex = 9999999999;
                document.getElementsByClassName(
                  "sign_up_steps"
                )[ 0 ].style.opacity = 1;
                document
                  .getElementsByClassName( "container" )[ 0 ]
                  .classList.remove( "container_active" );
              } }
                style={ { cursor: "pointer" } }>
                Register Now
              </button>
            </div>
          </div>
          {/* <div className="screen__background">
            <span className="screen__background__shape screen__background__shape4"></span>
            <span className="screen__background__shape screen__background__shape3"></span>
            <span className="screen__background__shape screen__background__shape2"></span>
            <span className="screen__background__shape screen__background__shape1"></span>
          </div> */}
        </div>
      </div>

      {/* Forgot 3 Step Popup End  */ }

      {/* Forgot 4 Step Popup Start  */ }

      <div className="container" id="forgot1_step">
        <div className="screen">
          <div className="screen__content">
            <form className="login" action="javascript:void(0)">
              <h1>Reset Password</h1>

              <div className="login__field login__field_first" style={ { display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: '145px' } }>
                <i className="bi bi-check-circle-fill" style={ { fontSize: '60px', color: 'green' } }></i>

                <h5 style={ { marginTop: '15px' } }>Password is Reset</h5>
                <p>Login using new password.</p>

              </div>



            </form>


          </div>
          {/* <div className="screen__background">
            <span className="screen__background__shape screen__background__shape4"></span>
            <span className="screen__background__shape screen__background__shape3"></span>
            <span className="screen__background__shape screen__background__shape2"></span>
            <span className="screen__background__shape screen__background__shape1"></span>
          </div> */}
        </div>
      </div>

      {/* Forgot 4 Step Popup End  */ }

      {/* Sign up start  */ }

      <div className="sign_up_steps">
        <div className="signn_up">
          <div className="sign_step_one" style={ { display: "none" } }>
            <div className="steps">
              <div className=" step_card_active step_card">
                <h6>
                  <i className="bi bi-phone-fill"></i>
                </h6>
                <span>Account</span>
              </div>
              <div className="step_card">
                <h6>
                  <i className="bi bi-key-fill"></i>
                </h6>
                <span>Otp</span>
              </div>
              <div className="step_card">
                <h6>
                  <i className="bi bi-person-fill"></i>
                </h6>
                <span>Details</span>
              </div>
              <div className="step_card">
                <h6>
                  <i className="bi bi-body-text"></i>
                </h6>
                <span>Decription</span>
              </div>
              <div className="step_card">
                <h6>
                  <i className="bi bi-person-lines-fill"></i>
                </h6>
                <span>Profile</span>
              </div>
              <div className="step_card">
                <h6>
                  <i className="bi bi-cloud-arrow-up-fill"></i>
                </h6>
                <span>Photo</span>
              </div>
              <div className="step_card">
                <h6>
                  <i className="bi bi-people-fill"></i>
                </h6>
                <span>Choose</span>
              </div>
              <div className="step_card">
                <h6>
                  <i className="bi bi-pencil-fill"></i>
                </h6>
                <span>Eduction</span>
              </div>
            </div>
          </div>

          <form className="register" onSubmit={ handleRegister }>
            <div className="one_sign_up">
              {/* One  */ }
              <div className="first_step one">
                <div className="card_bx">
                  <i className="bi bi-person-fill"></i>
                  <div className="icon_fade"></div>
                </div>
                <div className="card_bx">
                  <h3>This Profile is for</h3>
                  <input
                    type="hidden"
                    name="profile_for"
                    value={ activeProfile }
                    onChange={ () => { } }
                  />
                  <ul>
                    { profiles.map( ( profile ) => (
                      <li
                        key={ profile }
                        onClick={ () => setProfileFor( profile ) }
                        className={
                          activeProfile === profile ? "activeProfile" : ""
                        }
                      >
                        <i className="bi bi-check-circle-fill"></i> { profile }
                      </li>
                    ) ) }
                  </ul>
                  <div className="gender">
                    <h3>Gender</h3>
                    <input
                      type="hidden"
                      name="gender"
                      value={ profileDetails.gender }
                      onChange={ () => { } }
                    />
                    <ul>
                      <li
                        onClick={ () => handleGenderSelect( "Male" ) }
                        className={
                          profileDetails.gender === "Male"
                            ? "activeProfile"
                            : ""
                        }
                      >
                        <i className="bi bi-check-circle-fill"></i> Male
                      </li>
                      <li
                        onClick={ () => handleGenderSelect( "Female" ) }
                        className={
                          profileDetails.gender === "Female"
                            ? "activeProfile"
                            : ""
                        }
                      >
                        <i className="bi bi-check-circle-fill"></i> Female
                      </li>
                    </ul>
                  </div>
                  <div className="card_bx">
                    <button onClick={ () => handleContinue( 1 ) } type="button">
                      Continue
                    </button>
                  </div>
                </div>
              </div>

              <div className="first_step two">
                <div className="card_bx">
                  <i className="bi bi-person-fill"></i>
                  <div className="icon_fade"></div>
                </div>
                <div className="card_bx">
                  <label htmlFor="First_Name">
                    <i className="bi bi-person-fill"></i>First Name
                  </label>
                  <input
                    type="text"
                    placeholder="First Name"
                    name="firstName"
                    value={ profileDetails.firstName }
                    onChange={ handleInputChange }
                  />
                </div>
                <div className="card_bx">
                  <label htmlFor="Last Name">
                    <i className="bi bi-body-text"></i> Last Name
                  </label>
                  <input
                    type="text"
                    placeholder="Last Name"
                    name="lastName"
                    value={ profileDetails.lastName }
                    onChange={ handleInputChange }
                  />
                </div>

                <div
                  className="filter_card filterIncome"
                  style={ { zIndex: 9999999 } }
                >
                  <label htmlFor="Looking For a">
                    <i className="bi bi-person-raised-hand"></i>Height
                  </label>
                  <br />
                  <input
                    type="hidden"
                    name="height_in_inches"
                    value={ heightInch } onChange={ () => { } }
                  />
                  <input
                    type="text"
                    placeholder="Select"
                    value={ height }
                    onFocus={ () => handelFoucs( "height_drop_bx" ) }
                    onBlur={ () => handelFoucsout( "height_drop_bx" ) } onChange={ () => { } }
                  />
                  <i className="bi bi-caret-down-fill"></i>
                  <div className="drop_bx" id="height_drop_bx">
                    { heights.map( ( el, i ) => (
                      <li
                        key={ i }
                        onClick={ () =>
                        {
                          setHeight( el );
                          setHeightInch( heightInchs[ i ] );
                        } }
                      >
                        { el }
                      </li>
                    ) ) }
                  </div>
                </div>

                <div className="card_bx">
                  <label htmlFor="Date Of Birth">
                    <i className="bi bi-cake-fill"></i>Date Of Birth
                  </label>
                  <input
                    type="date"
                    placeholder="Date Of Birth"
                    name="dateOfBirth"
                    value={ profileDetails.dateOfBirth }
                    onChange={ handleInputChange }
                  />
                </div>

                <div className="card_bx">
                  <button onClick={ () => handleContinue( 2 ) } type="button">
                    Continue
                  </button>
                </div>
              </div>

              <div className="first_step two first_step_drop ">
                <div className="card_bx">
                  <i className="bi bi-person-fill"></i>
                  <div className="icon_fade"></div>
                </div>
                <div className="filter_card" style={ { zIndex: 9999999 } }>
                  <label htmlFor="Looking For a">
                    <i className="bi bi-plus-circle-fill"></i> Follower of
                  </label>
                  <br />
                  <input
                    type="text"
                    placeholder="Select"
                    value={ community }
                    onFocus={ () => handelFoucs( "follower_of_drop_bx" ) }
                    onBlur={ () => handelFoucsout( "follower_of_drop_bx" ) } onChange={ () => { } }
                  />
                  <i className="bi bi-caret-down-fill"></i>
                  <div className="drop_bx" id="follower_of_drop_bx">
                    { communityes.map( ( el, i ) => (
                      <li key={ i } onClick={ () => SetCommunity( el ) }>
                        { el }
                      </li>
                    ) ) }
                  </div>
                </div>
                <div className="filter_card" style={ { zIndex: 999999 } }>
                  <label htmlFor="Looking For a">
                    <i className="bi bi-caret-down-square-fill"></i> Religion
                  </label>
                  <br />
                  <input
                    type="text"
                    placeholder="Select"
                    value={ religion }
                    onFocus={ () => handelFoucs( "religions _drop_bx" ) }
                    onBlur={ () => handelFoucsout( "religions _drop_bx" ) } onChange={ () => { } }
                  />
                  <i className="bi bi-caret-down-fill"></i>
                  <div className="drop_bx" id="religions _drop_bx">
                    { religions.map( ( el, i ) => (
                      <li key={ i } onClick={ () => Setreligion( el ) }>
                        { el }
                      </li>
                    ) ) }
                  </div>
                </div>
                <div className="filter_card" style={ { zIndex: 99999 } }>
                  <label htmlFor="Looking For a">
                    <i className="bi bi-person-heart"></i> Marital Status
                  </label>
                  <br />
                  <input
                    type="text"
                    placeholder="Select"
                    value={ maritalStatus }
                    onFocus={ () => handelFoucs( "marital_drop_bx" ) }
                    onBlur={ () => handelFoucsout( "marital_drop_bx" ) } onChange={ () => { } }
                  />
                  <i className="bi bi-caret-down-fill"></i>
                  <div className="drop_bx" id="marital_drop_bx">
                    { maritalStatuses.map( ( el, i ) => (
                      <li key={ i } onClick={ () => setMaritalStatus( el ) }>
                        { el }
                      </li>
                    ) ) }
                  </div>
                </div>

                <div className="card_bx">
                  <button onClick={ () => handleContinue( 3 ) } type="button">
                    Continue
                  </button>
                </div>
              </div>

              {/* Two  */ }
              <div className="first_step two">
                <div className="card_bx">
                  <i className="bi bi-person-fill"></i>
                  <div className="icon_fade"></div>
                </div>

                <div className="filter_card" style={ { zIndex: 99999 } }>
                  <label htmlFor="Country">
                    <i className="bi bi-buildings"></i>Country
                  </label>
                  <i className="bi bi-caret-down-fill"></i>
                  <input
                    type="text"
                    placeholder="Select"
                    value={ country }
                    onFocus={ () => handelFoucs( "country_drop_bx" ) }
                    onBlur={ () => handelFoucsout( "country_drop_bx" ) } onChange={ () => { } }
                  />

                  <div className="drop_bx" id="country_drop_bx">
                    { countries.map( ( el, i ) => (
                      <li
                        key={ i }
                        onClick={ () =>
                        {
                          setCountry( el );
                          changCountry( el );
                        } }
                      >
                        { el }
                      </li>
                    ) ) }
                  </div>
                </div>

                <div className="filter_card" style={ { zIndex: 9999 } }>
                  <label htmlFor="State">
                    <i className="bi bi-buildings"></i>State
                  </label>
                  <i className="bi bi-caret-down-fill"></i>
                  <input
                    type="text"
                    placeholder="Select"
                    value={ state }
                    onFocus={ () => handelFoucs( "state_drop_bx" ) }
                    onBlur={ () => handelFoucsout( "state_drop_bx" ) } onChange={ () => { } }
                  />

                  <div className="drop_bx" id="state_drop_bx">
                    { states.map( ( el, i ) => (
                      <li
                        key={ i }
                        onClick={ () =>
                        {
                          setState( el );
                          changState( country, el );
                        } }
                      >
                        { el }
                      </li>
                    ) ) }
                  </div>
                </div>

                <div className="filter_card">
                  <label htmlFor="City">
                    <i className="bi bi-buildings"></i>City
                  </label>
                  <i className="bi bi-caret-down-fill"></i>
                  <input
                    type="text"
                    placeholder="Select"
                    value={ city }
                    onFocus={ () => handelFoucs( "city_drop_bx" ) }
                    onBlur={ () => handelFoucsout( "city_drop_bx" ) } onChange={ () => { } }
                  />

                  <div className="drop_bx" id="city_drop_bx">
                    { cities.map( ( el, i ) => (
                      <li key={ i } onClick={ () => setCity( el ) }>
                        { el }
                      </li>
                    ) ) }
                  </div>
                </div>

                <div className="card_bx">
                  <button onClick={ () => handleContinue( 4 ) } type="button">
                    Continue
                  </button>
                </div>
              </div>

              <div
                className="first_step two first_step_drop"
                style={ { zIndex: 9999 } }
              >
                <div className="card_bx">
                  <i className="bi bi-person-fill"></i>
                  <div className="icon_fade"></div>
                </div>

                <div className="filter_card">
                  <label htmlFor="Looking For a">
                    <i className="bi bi-translate"></i> Mother Tongue
                  </label>
                  <br />
                  <input
                    type="text"
                    placeholder="Select"
                    value={ motherTongue }
                    onFocus={ () => handelFoucs( "mother_tongue_drop_bx" ) }
                    onBlur={ () => handelFoucsout( "mother_tongue_drop_bx" ) } onChange={ () => { } }
                  />
                  <i className="bi bi-caret-down-fill"></i>
                  <div className="drop_bx" id="mother_tongue_drop_bx">
                    { motherTongues.map( ( el, i ) => (
                      <li key={ i } onClick={ () => setMotherTongue( el ) }>
                        { el }
                      </li>
                    ) ) }
                  </div>
                </div>
                <div className="card_bx">
                  <label htmlFor="Email">
                    <i className="bi bi-envelope-fill"></i>Email
                  </label>
                  <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={ profileDetails.email }
                    onChange={ handleInputChange }
                  />
                </div>
                <div className="card_bx">
                  <label htmlFor="Phone">
                    <i className="bi bi-phone-fill"></i>Phone
                  </label>
                  <input
                    type="text"
                    placeholder="Phone"
                    name="phone"
                    value={ profileDetails.phone }
                    onChange={ handleInputChange }
                  />
                </div>

                <div className="card_bx">
                  <button onClick={ () => handleContinue( 5 ) } type="button">
                    Continue
                  </button>
                </div>
              </div>

              <div className="first_step two">
                <div className="card_bx">
                  <i className="bi bi-person-fill"></i>
                  <div className="icon_fade"></div>
                </div>
                <div
                  className="filter_card filterIncome"
                  style={ { zIndex: 999999 } }
                >
                  <label htmlFor="Highest qualification">
                    <i className="bi bi-backpack2-fill"></i>Highest
                    qualification
                  </label>
                  <i className="bi bi-caret-down-fill"></i>

                  <input
                    type="text"
                    placeholder="Select"
                    value={ qualification }
                    onFocus={ () => handelFoucs( "qual_drop_bx" ) }
                    onBlur={ () => handelFoucsout( "qual_drop_bx" ) } onChange={ () => { } }
                  />

                  <div className="drop_bx" id="qual_drop_bx">
                    { highQualificaion.map( ( el, i ) => (
                      <li key={ i } onClick={ () => setQualification( el ) }>
                        { el }
                      </li>
                    ) ) }
                  </div>
                </div>
                <div className="card_bx">
                  <label htmlFor="Company Name">
                    <i className="bi bi-building-fill"></i>Company Name
                  </label>
                  <input
                    type="text"
                    placeholder="Company Name"
                    name="company"
                    value={ profileDetails.company }
                    onChange={ handleInputChange }
                  />
                </div>
                <div
                  className="filter_card filterIncome"
                  style={ { zIndex: 99999 } }
                >
                  <label htmlFor="Income">
                    <i className="bi bi-cash-stack"></i>Monthly Income
                  </label>
                  <i className="bi bi-caret-down-fill"></i>
                  <input
                    type="text"
                    placeholder="Select"
                    value={ income }
                    onFocus={ () => handelFoucs( "incomeRange_drop_bx" ) }
                    onBlur={ () => handelFoucsout( "incomeRange_drop_bx" ) } onChange={ () => { } }
                  />

                  <div className="drop_bx" id="incomeRange_drop_bx">
                    { incomeRange.map( ( el, i ) => (
                      <li key={ i } onClick={ () => setIncome( el ) }>
                        { el }
                      </li>
                    ) ) }
                  </div>
                </div>

                <div className="card_bx">
                  <label htmlFor="Designation">
                    <i className="bi bi-pen-fill"></i>Designation
                  </label>
                  <input
                    type="text"
                    placeholder="Designation"
                    name="designation"
                    value={ profileDetails.designation }
                    onChange={ handleInputChange }
                  />
                </div>

                <div className="card_bx">
                  <button onClick={ () => handleContinue( 6 ) } type="button">
                    Continue
                  </button>
                </div>
              </div>

              <div className="first_step two pp">
                <div className="card_bx">
                  <i className="bi bi-person-fill"></i>
                  <div className="icon_fade"></div>
                </div>
                <div className="card_bx">
                  <label
                    htmlFor="Highest qualification"
                    style={ { width: "100px", textWrap: "nowrap" } }
                  >
                    <i className="bi bi-person-square"></i> Profile Photo
                  </label>
                  <div className="profile_signUp">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={ handleImageChange }
                      name="photo"
                    />
                    <img src={ selectedImage } alt="Selected" id="selected_img" />
                  </div>
                </div>
                <div className="card_bx">
                  <label htmlFor="About my profile">
                    <i className="bi bi-body-text"></i>About my profile
                  </label>
                  <textarea
                    style={ { width: "90%" } }
                    type="text"
                    placeholder="About my profile"
                    name="aboutMyProfile"
                    value={ profileDetails.aboutMyProfile }
                    onChange={ handleInputChange }
                  />
                </div>

                <div className="card_bx">
                  <label htmlFor="Passowrd">
                    <i className="bi bi-eye-fill"></i>Password
                  </label>
                  <input
                    type="text"
                    placeholder="Password"
                    name="password"
                    value={ profileDetails.password }
                    onChange={ handleInputChange }
                  />
                </div>
                <div className="card_bx">
                  <button onClick={ () => handleContinue( 7 ) } type="button">
                    Continue
                  </button>
                </div>
              </div>

              <div className="first_step two">
                <div className="card_bx">
                  <i className="bi bi-person-fill"></i>
                  <div className="icon_fade"></div>
                </div>
                <div className="card_bx">
                  <div id="sendOtpDiv" style={ { textAlign: "center" } }>
                    <h4 style={ { margin: "15px" } }>
                      Send Otp to your{ " " }
                      { country != "India" ? "email id" : "mobile number" }
                    </h4>
                    <input
                      type="text"
                      name={ country != "India" ? "email" : "phone" }
                      value={
                        country != "India"
                          ? profileDetails.email
                          : profileDetails.phone
                      }
                      onChange={ handleInputChange }
                    />
                    <p></p>

                    <button
                      className="btn btn-primary"
                      type="button"
                      disabled
                      id="sendOTPLoad"
                      style={ { display: "none" } }
                    >
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Sending...
                    </button>

                    <div className="card_bx" id="sentOTPbtn">
                      <button type="button" onClick={ () => handleSendOtp( profileDetails.email, profileDetails.phone, country ) }>Send</button>
                    </div>
                  </div>

                  <div id="verifyOTpDiv" style={ { textAlign: "center", display: "none" } }>
                    <h4 style={ { margin: "15px" } }>
                      Please Verify your{ " " }
                      { country != "India" ? "email id" : "mobile number" }
                    </h4>
                    <i className="bi bi-phone" style={ { fontSize: "50px" } }></i>
                    <p>We have sent a 4 digit Otp to </p>
                    <p><br />

                      { country != "India"
                        ? profileDetails.email
                        : profileDetails.phone }
                      <span><i onClick={ handleEditPhone } className="bi bi-pencil-square" style={ { cursor: "pointer", marginLeft: "5px" } } title="Edit"></i></span></p><br />
                    <label htmlFor="Otp">
                      <i className="bi bi-key-fill"></i> Otp{ " " }
                      <span style={ { color: "black", cursor: "unset" } }>
                        Didn't receive the Otp ?
                      </span>
                      <span
                        id="resend_load"
                        style={ { cursor: "unset", display: "none" } }
                      >
                        sending...
                      </span>
                      <span
                        type="button"
                        id="re_send"
                        onClick={ () =>
                          handleReSendOtp(
                            profileDetails.email,
                            profileDetails.phone,
                            country
                          )
                        }
                      >
                        { " " }
                        Re-send
                      </span>
                    </label>
                    <input
                      type="text"
                      placeholder="XXXX"
                      name="otp"
                      value={ otp }
                      onChange={ handleChange }
                    />

                    <div
                      className="card_bx"
                      id="registerLoadBTN"
                      style={ { display: "none" } }
                    >
                      <button
                        className="btn btn-primary"
                        type="button"
                        disabled
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading...
                      </button>
                    </div>

                    <div className="card_bx" id="registerSubmitBTN">
                      <button>Submit</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          { scroll_s_bx != 0 ? (
            <i
              className="bi bi-arrow-left"
              onClick={ () =>
                scroll_s_bx != 0
                  ? setScroll_s_bx( scroll_s_bx - 1 )
                  : setScroll_s_bx( 0 )
              }
            ></i>
          ) : null }
          <i
            className="bi bi-x-circle-fill" id="sign_up_exit"
            onClick={ () =>
            {
              document.getElementsByClassName( "sign_up_steps" )[ 0 ].style.zIndex =
                -9999;
              document.getElementsByClassName(
                "sign_up_steps"
              )[ 0 ].style.opacity = 0;
            } }
          ></i>
          { signupError && (
            <p
              style={ {
                color: "rgb(142 0 33)",
                textAlign: "center",
                marginTop: "5px",
                fontWeight: "500",
              } }
            >
              { signupError }
            </p>
          ) }
        </div>
      </div>

      {/* Sign up end */ }
    </>
  );
};

export default Header;
